import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { IArticulo, IArticuloFull } from '../models/articulo';
import { IUser } from '@core/models/auth/user';
import { AuthLocalService } from '@core/auth/auth-local.service';


@Injectable()
export class ArticulosService {
  private user: IUser;
  static SIN_ESTADO = 'Sin Estado'
  static PREETIQUETADO = 'Preetiquetado'
  static ETIQUETADO = 'Etiquetado'
  static INVENTARIADO = 'Inventariado'

  constructor(private http: HttpClient, private _authLocal: AuthLocalService) {
    this.user = this._authLocal.getCurrentUser();
  }

  getArticulos(filter: any): Observable<IArticulo[]> {
    var subject = new Subject<any>();
    this.http.get<IArticulo[]>(environment.api.materiales.articulos.view_articulos + filter, { observe: 'response' })
      .subscribe((resp: any) => {
        subject.next({
          'list': resp.body,
          'totalPages': resp.headers.get('x-pagination-page-count'),
          'totalCount': resp.headers.get('x-pagination-total-count')
        })
      });
    return subject.asObservable();
  }

  getArticulosFull(filtro?: any): Observable<IArticulo[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.articulos + (filtro ? filtro : ''));
  }

  unificarArticulo(payload: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.unificar, payload);
  }

  getArticulosContadores(filtro: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.contadores + (filtro ? filtro : ''));
  }

  getArticulosContador(estado: string): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.contador + "?estado=" + estado);
  }

  getViewArticuloStockPanols(filtro?: string): Observable<IArticulo> {
    let url = environment.api.materiales.articulos.viewArticuloStockPanols + filtro;
    return this.http.get<any>(url);
  }

  getViewArticuloMovimientoStocks(filtro?: any): Observable<any> {
    return this.http.get<any>(environment.api.materiales.articulos.viewArticuloMovimientoStocks + (filtro ? filtro : ''));
  }

  getArticulo(id: any): Observable<IArticulo> {
    return this.http.get<any>(environment.api.materiales.articulos.articulos + `/${id}`);
  }

  eliminarArticulo(id: any): Observable<IArticulo> {
    return this.http.delete<any>(environment.api.materiales.articulos.articulos + `/${id}`);
  }

  getRubros(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.rubros + (filtro ? filtro : ''));
  }

  getSectores(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.sectores + (filtro ? filtro : ''));
  }

  getSubSectores(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.subsectores + (filtro ? filtro : ''));
  }

  getProveedores(): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.proveedores);
  }

  getUnidadMedidas(): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.unidadMedida);
  }

  getArtUnidadMedidas(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.artUnidadMedida + (filtro ? filtro : ''));
  }
  getViewArtUnidadMedidas(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.viewArtUnidadMedida + (filtro ? filtro : ''));
  }
  cambiarEquivalencia(datos?: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.cambiar_equivalencia, datos);
  }

  getArtProveedores(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.articulos.artProveedores + (filtro ? filtro : ''));
  }

  getImagen(filtro?: any): Observable<any> {
    return this.http.get<any[]>(environment.api.materiales.articulos.imagenes + (filtro ? filtro : ''));
  }
  /**recibe por parámetro el artículo y el Codigo o id del artículo */
  editArticulo(articulo: any, idArticulo: any): Observable<any> {
    return this.http.put(environment.api.materiales.articulos.articulos + `/${idArticulo}`, articulo);
  }

  createArticulo(articulo: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.articulos, articulo);
  }

  deleteArtUnidadMedida(unidad: any): Observable<any> {
    return this.http.delete(`${environment.api.materiales.articulos.artUnidadMedida}/${unidad.codigo}`);
  }

  deleteArtProveedor(proveedoresSelectedAux: any[]): Observable<any> {
    proveedoresSelectedAux.forEach(async proveedor => {
      await this.http.delete(`${environment.api.materiales.articulos.artProveedores}/${proveedor.Codigo}`);
    })
    return
  }

  createArtProveedor(prov: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.artProveedores, prov);
  }

  createArtUnidadMedida(artUM: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.artUnidadMedida, artUM);
  }

  getMovimientos(filtro: string): Observable<any> {
    let formData = new FormData();
    formData.append('auditoria', this.user.nick);
    formData.append('usuario_auditoria', this.user.codigo.toString());
    formData.append('servicio', 'get_stock_movimiento');
    formData.append('filtro', filtro);
    return this.http.post(environment.controlador, formData);
  }

  getMovimientosGrafico(id: any): Observable<any> {
    return this.http.get(`${environment.api.materiales.articulos.movimientosGrafico}?filter[articulo]=${id}`);
  }

  createRubro(rubro: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.rubros, rubro);
  }

  createSector(sector: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.sectores, sector);
  }

  createSubsector(subsector: any): Observable<any> {
    return this.http.post(environment.api.materiales.articulos.subsectores, subsector);
  }

}
