import { AppConfig, Scheme, Theme, Themes } from 'app/core/config/app.config';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { Layout } from 'app/shared/components/layout/layout.types';
import { Router } from '@angular/router';
import { AuthLocalService } from '@core/auth/auth-local.service';
import { IUser } from '@core/models/auth/user';
import { FuseDrawerComponent } from '@fuse/components/drawer';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styles: [
    `
      settings {
        position: static;
        display: block;
        flex: none;
        width: auto;
      }

      @media (screen and min-width: 1280px) {
        empty-layout + settings .settings-cog {
          right: 0 !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit, OnDestroy {
  config: AppConfig;
  layout: Layout;
  scheme: 'dark' | 'light';
  theme: string;
  themes: Themes;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: IUser;

  @ViewChild('settingsDrawer') public drawer: FuseDrawerComponent
  /**
   * Constructor
   */
  constructor(private _router: Router, private _fuseConfigService: FuseConfigService, private authService: AuthLocalService, private cd: ChangeDetectorRef) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: AppConfig) => {
      this.user = this.authService.getCurrentUser();
      // Store the config
      this.config = this.user
        ? {
          ...config,
          theme: this.user.theme ? this.user.theme : config.theme,
          layout: this.user.layout ? (this.user.layout as Layout) : config.layout,
          scheme: this.user.scheme ? (this.user.scheme as Scheme) : config.scheme,
        }
        : config;
      this.cd.detectChanges();
      this.drawer.close();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the layout on the config
   *
   * @param layout
   */
  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this._router
      .navigate([], {
        queryParams: {
          layout: null,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        // Set the config
        this.authService.setLayout(layout);
        this._fuseConfigService.config = { layout };
      });
  }

  /**
   * Set the scheme on the config
   *
   * @param scheme
   */
  setScheme(scheme: Scheme): void {
    this.authService.setScheme(scheme);
    this._fuseConfigService.config = { scheme };
  }

  /**
   * Set the theme on the config
   *
   * @param theme
   */
  setTheme(theme: Theme): void {
    this.authService.setTheme(theme);
    this._fuseConfigService.config = { theme };
  }
}
