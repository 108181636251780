import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { IUser } from '@core/models/auth/user';
import { AuthLocalService } from '@core/auth/auth-local.service';
import { IArticulo } from '@features/materiales/articulos/models/articulo';

@Injectable()
export class StockPanolService {
  private user: IUser;
  static tituloPanolSel :string;
  constructor(private http: HttpClient, private _authLocal: AuthLocalService) {
    this.user = this._authLocal.getCurrentUser();
  }
  getStocksPorPanol(filtro: any): Observable<any> {
    var subject = new Subject<any>();
    this.http.get<any>((environment.api.materiales.stock_panol.view_stock_panols + (filtro ? filtro : '')), { observe: 'response' })
      .subscribe((resp: any) => {
        subject.next({
          'list': resp.body,
          'totalPages': resp.headers.get('x-pagination-page-count'),
          'totalCount': resp.headers.get('x-pagination-total-count')
        })
      });
    return subject.asObservable();
  }
  getStockPanols(filtro?: any): Observable<any[]> {
    return this.http.get<any[]>(environment.api.materiales.stock_panol.stock_panols + (filtro ? filtro : ''));
  }
  getArticulo(id: any): Observable<IArticulo> {
    return this.http.get<any>(environment.api.materiales.articulos.articulos + `/${id}`);
  }
}