import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormateadorService {
    constructor() {}

    formatInput(value: string): string {
        // Elimina caracteres no deseados
        let cleanedValue = value.replace(/[^\d.,]/g, '');
    
        // Reemplaza el separador de miles (punto) por nada
        cleanedValue = cleanedValue.replace(/\./g, '');
    
        // Reemplaza el separador decimal (coma) por un punto
        cleanedValue = cleanedValue.replace(',', '.');
    
        return cleanedValue;
    }

    // Manejar el blur para moneda
    onCurrencyBlur(control: AbstractControl): void {
        const value = control.value;
        if (value !== null && value !== '') {
            const numericValue = this.parseCurrency(value); 
            if (!isNaN(numericValue)) {
                // Actualizar el control con el valor numérico
                control.setValue(numericValue, { emitEvent: false });
                // Mostrar el valor en formato monetario
                control.setValue(this.formatCurrency(numericValue), { emitEvent: false });
            } else {
                control.setValue(0, { emitEvent: false }); // O manejar como consideres
            }
        }
    }
    
    // Convertir el valor numérico a formato moneda
    formatCurrency(value: number): string {
        return new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }
    
    // Eliminar todos los símbolos de moneda y formatear el valor a un número limpio
    parseCurrency(value: any): number {
        if (typeof value !== 'string') {
            value = String(value);
        }
        value = value.replace(/[^\d,.-]/g, '');
        let cleanValue = value.replace(/\./g, '').replace(',', '.');
        const result = parseFloat(cleanValue);
        return isNaN(result) ? 0 : result; // Retornar 0 si no es un número válido
    }

    formatPorcentaje(value: number): string {
        // Verificar que el valor sea un número
        if (typeof value !== 'number') {
            return '0 %'; // o manejar el error según sea necesario
        }
        
        // Convertir el valor a string con dos decimales y eliminar los ceros innecesarios
        return value.toFixed(2).replace(/\.0+$/, '') + ' %';
    }

    // Desformatear un porcentaje
    parsePorcentaje(value: any): number {
        // Asegurarse de que el valor sea una cadena
        if (typeof value !== 'string') {
            value = String(value);
        }
    
        // Verificar si el valor es un porcentaje y eliminar el símbolo
        if (value.includes('%')) {
            value = value.replace('%', '');
        }
    
        // Eliminar cualquier símbolo no numérico
        let cleanValue = value.replace(/[^\d.,]/g, '');
        
        // Convertir el formato
        cleanValue = cleanValue.replace(/\./g, '').replace(',', '.');
    
        // Retornar el número, asegurando que no se pase un valor inválido
        const parsedValue = parseFloat(cleanValue);
        
        // Retornar el valor como entero, asegurando que no sea un número inválido
        return isNaN(parsedValue) ? 0 : Math.round(parsedValue); // Retornar 0 si no es un número
    }

    // Manejar el desenfoque para el porcentaje
    onPercentageBlur(control: AbstractControl): void {
        const value = control.value;
        if (value === null || value === '') {
            control.setValue('', { emitEvent: false });
            return;
        }

        const numericValue = this.parsePorcentaje(value);
        control.setValue(this.formatPorcentaje(numericValue), { emitEvent: false });
    }
}