import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'custom-snackbar',
    template: `
        <div class="custom-snackbar">
            <span>{{ message }}</span>
        </div>
    `,
    styles: [`
        .custom-snackbar {
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #FBBF24;
            color: #fff;
            padding: 16px;
            border-radius: 4px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 1000;
        }
        button {
            color: #fff;
        }
    `],
})
export class CustomSnackbarComponent {
    @Input() message: string;
    @Output() reload = new EventEmitter<void>();

    onReload(): void {
        this.reload.emit();
    }
}