<div *ngIf="showControls"
     class="h-full w-fit px-6 absolute top-0 left-0 items-center flex justify-center md:hover:bg-black md:hover:opacity-70 cursor-pointer duration-200 z-9999"
     (click)="prev()">
  <mat-icon svgIcon="mat_outline:arrow_back_ios" class="text-white"></mat-icon>
</div>
<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="flex list-none m-0 p-0" #carousel>
    <li *ngFor="let slide of slides; let i = index" #carouselItemElement>
      <ng-container [ngTemplateOutlet]="slide.tpl"></ng-container>
    </li>
  </ul>
</section>
<div *ngIf="showControls"
     class="h-full w-fit px-6 absolute top-0 right-0 items-center flex justify-center md:hover:bg-black md:hover:opacity-70 cursor-pointer duration-200 z-9999"
     (click)="next()">
  <mat-icon svgIcon="mat_outline:arrow_forward_ios" class="text-white"></mat-icon>
</div>