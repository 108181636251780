export interface Notification {
  alerta: number;
  codigo: number;
  descripcion: string;
  emisor: string;
  fecha: string;
  leido: number;
  receptor: number;
  tipo: number;
  read?: boolean;
}

export interface IAlerta {
  Codigo: number;
  Alerta: number;
  Leido: number;
  Usuario: number;
  notificar?: number;
}

export const NotificationType = [
  { icon: "mat_solid:mail", text: "Correo", tipo: 0 },
  { icon: "mat_solid:account_balance", text: "Caja", tipo: 1 },
  { icon: "mat_solid:attach_money", text: "Actualización Precios", tipo: 2 },
  { icon: "mat_solid:message", text: "Mensaje", tipo: 3 },
  { icon: "mat_solid:supervised_user_circle", text: "Empleados", tipo: 4 },
  { icon: "mat_solid:settings", text: "Soporte Testeo Pendiente", tipo: 5 },
  { icon: "mat_solid:group_work", text: "Internos", tipo: 7 },
  { icon: "mat_solid:people", text: "Clientes", tipo: 8 },
  { icon: "mat_solid:shopping_cart", text: "Puerta Placa Entregas", tipo: 9 },
  { icon: "mat_solid:settings_applications", text: "Maquinarias", tipo: 10 },
  { icon: "mat_solid:assignment_late", text: "Carnet Vencido", tipo: 11 },
  { icon: "mat_solid:playlist_add_check", text: "Recepción Presupuesto", tipo: 12 },
  { icon: "mat_solid:library_books", text: "Puerta Placa Pedidos", tipo: 13 },
  { icon: "mat_solid:restore_page", text: "Venta Modificada", tipo: 14 },
  { icon: "mat_solid:description", text: "Solicitud Hoja Ajuste", tipo: 15 },
  { icon: "mat_solid:shopping_cart", text: "Stock Min Artículo", tipo: 16 },
  { icon: "mat_solid:history", text: "Actualización Sistema", tipo: 17 },
  { icon: "mat_solid:create_new_folder", text: "Solicitud Archivo", tipo: 18 },
  { icon: "mat_solid:receipt", text: "Prepedidos", tipo: 19 },
  { icon: "mat_solid:loyalty", text: "Preetiquetados", tipo: 20 },
  { icon: "mat_solid:account_balance", text: "Contabilizar", tipo: 21 },
  { icon: "mat_solid:supervised_user_circle", text: "Prospecto Pend. Superv.", tipo: 22 },
  { icon: "mat_solid:assignment_ind", text: "Prospecto Asignación", tipo: 23 },
  { icon: "mat_solid:shopping_cart", text: "Artículo Punto Pedido", tipo: 24 },
  { icon: "mat_solid:price_check", text: "Venta Cerrada", tipo: 25 },
  { icon: "mat_solid:add_shopping_cart", text: "Ingreso de Stock", tipo: 26 },
  { icon: "mat_solid:work", text: "Otros", tipo: 6 },
];
