// breadcrumbs.component.ts

import { Component } from '@angular/core';
import { BreadcrumbItem, BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  
})
export class BreadcrumbsComponent {
  constructor(private breadcrumbService: BreadcrumbService) {}

  navigateTo(index: number): void {
    this.breadcrumbService.navigateToBreadcrumb(index);
  }

  getBreadcrumbs(): BreadcrumbItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
}
