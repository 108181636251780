import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { IProspectoRoot } from '@shared/models/prospectos-root';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, tap} from 'rxjs';

@Injectable()
export class ProspectosRootService {
   prospectos$: ReplaySubject<IProspectoRoot> = new ReplaySubject<IProspectoRoot>();
  constructor(private http: HttpClient) {}

  get CantidadProspectos(): Observable<IProspectoRoot> {
    return this.prospectos$.asObservable();
  }

  getAll(): Observable<IProspectoRoot> {
    console.log("PROSPECTOS ROOT SERVICE");
    return this.http.get<IProspectoRoot>(environment.api.ventas.prospectos.contadores,{
      headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
  }).pipe(
      tap((prospecto) => {
        this.prospectos$.next(prospecto);
      })
    )
  }
}