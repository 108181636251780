import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { SearchService } from './search.service';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'fuseSearch',
  animations: fuseAnimations,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() appearance: 'basic' | 'bar' = 'basic';
  @Input() debounce: number = 750;
  @Input() minLength: number = 2;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  opened: boolean = false;
  resultSets: any[];
  textoBusqueda = new Subject<string>();
  /* private _unsubscribeAll: Subject<any> = new Subject<any>(); */
  /**
   * Constructor
   */
  constructor(
    /* private _elementRef: ElementRef,
    private _renderer2: Renderer2, */
    private _searchService: SearchService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened': this.opened,
    };
  }

  /**
   * Setter for bar search input
   *
   * @param value
   */
  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef) {
    // If the value exists, it means that the search input
    // is now in the DOM, and we can focus on the input..
    if (value) {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {
        // Focus to the input element
        value.nativeElement.focus();
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Appearance
    this.textoBusqueda = new Subject<string>();
    if ('appearance' in changes) {
      // To prevent any issues, close the
      // search after changing the appearance
      this.close(true);
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this._searchService.setSearchInput(new FormControl());
    /* this._searchService.setFilterButton(document.getElementById("filtroGeneral")); */
    /* this.textoBusqueda.pipe(
      debounceTime(750),
      distinctUntilChanged())
      .subscribe(value => {
        console.log(value)
        this._searchService.setSearchText(this.textoBusqueda);
      }); */
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    /* this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete(); */
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On keydown of the search input
   *
   * @param event
   */
  onKeydown(event: KeyboardEvent): void {
    if ((event.code === 'Escape' || event.code === 'Enter') && this.appearance === 'bar') {
      this.close(event.code === 'Escape');
    }
  }

  //pasar en cada metodo de onfiltermenu de cada componente como origin el boton del header!
  //entonces ya tengo la referencia de donde abrir el menu, el resto lo maneja cada componente!!
  //en definitiva le indico que se abra en el boton de ashiba.
  //el tema es que deberia agregar un evento de onFilterMenu por ts.
  eventoFiltro(event: Event, origin: MatButton) {
    this._searchService.setFilterOrigin(origin)
    this._searchService.getFilterEvent().next(event)
  }
  /*   onFilterMenu(origin: MatButton) {
      if (this._searchService.getFilterComponent()) {
        this.menuService
          .open(this._searchService.getFilterComponent(),
            origin._elementRef,
            this._searchService.getFilterData())
          .afterClosed()
          .subscribe({
            next: filter => {
              if (filter) {
                this.filter = filter;
                this._tabsServices.setFiltroPresupuestos(this.filter);
                this.dispatchActions(filter);
                this.getData();
              }
            },
          });
      }
    } */


  /**
   * Open the search
   * Used in 'bar'
   */
  open(): void {
    // Return if it's already opened
    if (this.opened) {
      return;
    }
    this.textoBusqueda = new Subject<string>();
    // Open the search
    this.opened = true;
  }

  /**
   * Close the search
   * * Used in 'bar'
   */
  close(clear: boolean): void {
    // Return if it's already closed
    if (!this.opened) {
      return;
    }
    if (clear) {
      // Clear the search input
      this._searchService.getSearchInput().setValue('');
    }
    // Close the search
    this.opened = false;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}

