import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthLocalService } from './auth-local.service';
import { Injectable } from '@angular/core';
import { AuthUtils } from './auth.untils';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthLocalService) { }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    // if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
    // {
    if (this._authService.getCurrentToken() && !AuthUtils.isTokenExpired(this._authService.getCurrentToken())) {

      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this._authService.getCurrentToken())
      });
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0)) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          location.reload();
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
}
