import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from 'app/core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseModule } from '@fuse';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { RouterStoreModule } from '@root-store/root-store.module';
import { SharedModule } from '@shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRootModule } from '@ngrx/store';
import { appConfig } from 'app/core/config/app.config';
import { appRoutes } from 'app/app.routing';
import localeEsAr from '@angular/common/locales/es-AR';
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppAlertService } from '@shared/services/app/alert.service';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ProspectosRootService } from '@shared/services/app/prospectos-root.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ArticulosService } from '@features/materiales/articulos/services/articulos.service';
import { MatSelectModule } from '@angular/material/select';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PushNotificationService } from '@shared/services/firebase/push-notification.service';


const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'top',
  useHash: true,
};

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    // NgRx
    StoreRootModule,
    EffectsModule.forRoot(),
    StoreDevtoolsModule,
    RouterStoreModule,

    HttpClientModule,
    CoreModule,
    SharedModule,

    MatNativeDateModule,
    //NgQR
    NgxQRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),

  ],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    AppAlertService,
    DatePipe,
    MatDatepickerModule,
    ProspectosRootService,
    ArticulosService,
    MatSelectModule,
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-Ar' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    PushNotificationService
  ],
})
export class AppModule {}
