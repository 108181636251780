
export const TitleType = [
  { icon: "", text: "Home", path: "/" },
  { icon: "", text: "Mis Clientes", path: "/ventas/mis-clientes" },
  { icon: "", text: "Calendario", path: "/ventas/calendario" },
  { icon: "", text: "Book de Fotos", path: "/ventas/book" },
  { icon: "", text: "Nuevo Prospecto", path: "/prospectos/nuevo" },
  { icon: "", text: "Sin Asignar", path: "/prospectos/sin-asignar" },
  { icon: "", text: "Asignados", path: "/prospectos/asignados" },
  { icon: "", text: "Contactados", path: "/prospectos/contactados" },
  { icon: "", text: "Listos Para Cotizar", path: "/prospectos/para-cotizar" },
  { icon: "", text: "En Proceso de Cotización", path: "/prospectos/en-cotizacion" },
  { icon: "", text: "Pendientes de Supervisión", path: "/prospectos/pendientes-supervision" },
  { icon: "", text: "Aprobados", path: "/prospectos/aprobados" },
  { icon: "", text: "Enviados", path: "/prospectos/enviados" },
  { icon: "", text: "No Cotizados", path: "/prospectos/no-cotizados" },
  { icon: "", text: "Perdidos", path: "/prospectos/perdidos" },
  { icon: "", text: "Búsqueda", path: "/prospectos/busqueda" },
  { icon: "", text: "Estados/Vendedores", path: "/prospectos/estados" },
  { icon: "", text: "Seguimiento de Profesionales", path: "/ventas/seguimiento-profesionales" },
  { icon: "", text: "Seguimiento de Obras", path: "/ventas/seguimiento-obras" },
  { icon: "", text: "Oportunidades", path: "/ventas/oportunidades" },
  { icon: "", text: "Mediciones", path: "/ventas/mediciones" },
  { icon: "", text: "Carteles Obra", path: "/ventas/carteles-obra" },
  { icon: "", text: "Contactos", path: "/ventas/contactos" },
  { icon: "", text: "Remitos", path: "/taller/planta-pintura" },
  { icon: "", text: "Trazabilidad", path: "/taller/trazabilidad" },
  { icon: "", text: "Remitos AASA", path: "/taller/remitos-aasa" },
  { icon: "", text: "Artículos", path: "/materiales/articulos/listado" },
  { icon: "", text: "Pedido de Materiales", path: "/materiales/pedidos" },
  { icon: "", text: "Seguimiento de Órdenes de Compra", path: "/materiales/demora-oc" },
  { icon: "", text: "Inventario", path: "/materiales/inventario" },
  { icon: "", text: "Stock por Pañol", path: "/materiales/stock-panol" },
  { icon: "", text: "Búsquedas", path: "/rrhh/busquedas" },
  { icon: "", text: "Zonas", path: "/configuraciones/zonas" },
  { icon: "", text: "Reportes de Precios", path: "/configuraciones/precios" },
  { icon: "", text: "Remitos Internos", path: "/materiales/remitos-internos" },
  { icon: "", text: "Sueldos Digital", path: "/rrhh/sueldos" },
  { icon: "", text: "Calculadora de Abertura de Lista", path: "/calculadora/abertura-lista/CALCULAR" },
  { icon: "", text: "Abertura de Lista", path: "/calculadora/abertura-lista/CREAR" },
  { icon: "", text: "Abertura de Lista", path: "/calculadora/abertura-lista/EDITAR" },
  { icon: "", text: "Abertura de Lista", path: "/calculadora/abertura-lista/EDIT_NAV" },
  { icon: "", text: "Abertura de Lista", path: "/calculadora/abertura-lista/COMPONENTE_EDITABLE_CREAR" },
  { icon: "", text: "Abertura de Lista", path: "/calculadora/abertura-lista/COMPONENTE_EDITABLE_EDITAR" },
  { icon: "", text: "Calculadora de Abertura Editable", path: "/calculadora/save-abertura-editable/CALCULAR" },
  { icon: "", text: "Abertura Editable", path: "/calculadora/save-abertura-editable/CREAR" },
  { icon: "", text: "Abertura Editable", path: "/calculadora/save-abertura-editable/EDITAR" },
  { icon: "", text: "Abertura Editable", path: "/calculadora/save-abertura-editable/EDIT_NAV" },
  { icon: "", text: "Abertura Editable de Chapa", path: "/ventas/mis-clientes/save-abertura-editable-chapa/" },
  { icon: "", text: "Clientes", path: "/clientes/clientes" },
  { icon: "", text: "Nuevo Prospecto", path: "/clientes/nuevo-prospecto" },
  { icon: "", text: "Datos de Cotización", path: "/clientes/completar-datos-cotizacion" },
  { icon: "", text: "Edición de Presupuesto", path: "/ventas/mis-clientes/save/" },
  { icon: "", text: "Abertura Otra", path: "/ventas/mis-clientes/save-abertura-otra/" },
  { icon: "", text: "Calculadora de Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/CALCULAR" },
  { icon: "", text: "Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/CREAR" },
  { icon: "", text: "Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/EDITAR" },
  { icon: "", text: "Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/EDIT_NAV" },
  { icon: "", text: "Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/COMPONENTE_EDITABLE_CREAR" },
  { icon: "", text: "Puerta de Aluminio de Lista", path: "/calculadora/puerta-lista/COMPONENTE_EDITABLE_EDITAR" },
  { icon: "", text: "Abertura en Construcción", path: "/calculadora/abertura-en-construccion/EDIT_NAV" },
  { icon: "", text: "Calculadora de Portón de Chapa", path: "/calculadora/puerta-chapa/CALCULAR/PORTON" },
  { icon: "", text: "Cotización de Portón de Chapa", path: "/calculadora/puerta-chapa/CREAR/PORTON" },
  { icon: "", text: "Cotización de Portón de Chapa", path: "/calculadora/puerta-chapa/EDITAR/PORTON" },
  { icon: "", text: "Calculadora de Puerta de Chapa", path: "/calculadora/puerta-chapa/CALCULAR/PUERTA" },
  { icon: "", text: "Cotización de Puerta de Chapa", path: "/calculadora/puerta-chapa/CREAR/PUERTA" },
  { icon: "", text: "Cotización de Puerta de Chapa", path: "/calculadora/puerta-chapa/EDITAR/PUERTA" },
  { icon: "", text: "Cotización de Puerta de Chapa", path: "/calculadora/puerta-chapa/EDITAR/PUERTA/EDIT_NAV" },
  { icon: "", text: "Cotización de Portón de Chapa", path: "/calculadora/puerta-chapa/EDITAR/PORTON/EDIT_NAV" },
  { icon: "", text: "Calculadora de Puerta de Interior", path: "/calculadora/puerta-interior/CALCULAR" },
  { icon: "", text: "Puerta Interior", path: "/calculadora/puerta-interior/CREAR" },
  { icon: "", text: "Puerta Interior", path: "/calculadora/puerta-interior/EDITAR" },
  { icon: "", text: "Puerta Interior", path: "/calculadora/puerta-interior/EDIT_NAV" },
  { icon: "", text: "Planilla de Sueldos para Banco", path: "/reportes/planilla-sueldo-banco" },
  { icon: "", text: "Informe de Retenciones", path: "/reportes/informe-retenciones" },
  { icon: "", text: "Kilos de Aluminio por Proveedor", path: "/reportes/kilos-aluminio-proveedor" },
  { icon: "", text: "Facturación por Punto de Venta", path: "/reportes/facturacion-punto-venta" },
];
