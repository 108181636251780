import { Component, EventEmitter, Inject, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { CalculadoraVidrioService } from '@shared/services/calculadora-vidrio.service';

@Component({
  selector: 'app-calculadora-vidrios',
  templateUrl: './calculadora-vidrios.component.html',
  styleUrls: ['./calculadora-vidrios.component.scss']
})
export class CalculadoraVidriosComponent implements OnInit {
  protected camaras: any[];
  protected categorias: any[];
  protected categorias_menu: string[];
  protected vidrios: any[];
  protected ingresarInteres: number;
  protected mostrarPanio: boolean;
  protected mostrarInteres: boolean;
  protected formGeneral: FormGroup;
  protected mostrarSpinner: boolean = true;
  protected calculadora_vidrio: any[];
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;
  btVidrioEPanio = 'Vidrio Externo';
  btVidrioIPanio = 'Vidrio Interno';
  btCamaraPanio = 'Cámara';
  getCategoriasVidriosSubs$: any;
  getCamarasSubs$: any;
  tooltipVidrioEPanio: any;
  tooltipVidrioIPanio: any;
  tooltipCamaraPanio: any;
  spinnerMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    protected _calculadoraVidrioService: CalculadoraVidrioService,
    protected dialogo: MatDialogRef<CalculadoraVidriosComponent>
  ) {
    console.log("calculadora_vidrio", data)
    this.calculadora_vidrio = data;
  }
  ngOnInit(): void {
    this.newForm();
    this.getData().then(async data => {
      this.categorias_menu = this.categorias.map(cat => cat.Descripcion);
      /* console.log(data); */
      this.mostrarSpinner = false;
    })
    this.observeFormulario();
  }
  async getVidrios(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCategoriasVidriosSubs$ = this._calculadoraVidrioService.getCategoriaVidrios('?expand=vidrios_data').subscribe({
        next: (v) => {
          this.vidrios = v;
          this.categorias = v;
          resolve(true);
        },
        error: (e) => reject(false),
      })
    })
  }
  async getCamaras(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCamarasSubs$ = this._calculadoraVidrioService.getCamaras().subscribe({
        next: (v) => {
          this.camaras = v;
          resolve(true);
        },
        error: (e) => reject(false),
      })
    })
  } selectAll(event: any): void {
    event.target.select();
  }
  newForm() {
    this.formGeneral = new FormGroup({
      simpleVPanio: new FormControl('true'),
      interes: new FormControl(0, [Validators.pattern(/^\d+\.?\d*$/)]),
      ancho: new FormControl(0, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      alto: new FormControl(0, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      vidrioInternoPanio: new FormControl(2, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      vidrioExternoPanio: new FormControl(null, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      camaraPanio: new FormControl(null, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      cantidad: new FormControl(1, [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]),
      vidrio: new FormControl(null, [Validators.pattern(/^\d+\.?\d*$/)]),
      detalle: new FormControl('')
    })
    this.formGeneral.controls.vidrioExternoPanio.disable();
    this.formGeneral.controls.camaraPanio.disable();
  }
  async getData(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const vidrios = await this.getVidrios();
      const camaras = await this.getCamaras();
      resolve(vidrios + camaras)
    });
  }
  observeFormulario() {

    this.formGeneral.get('simpleVPanio').valueChanges
      .subscribe(value => {
        this.cambiaSimplePanioCheck(value).then(r => this.calcular);
      });
  }
  async cambiaSimplePanioCheck(value) {
    if (value === 'true') {
      this.formGeneral.controls.vidrioExternoPanio.disable();
      this.formGeneral.controls.camaraPanio.disable();
    } else {
      this.formGeneral.controls.vidrioExternoPanio.enable();
      this.formGeneral.controls.camaraPanio.enable();
    }
  }
  protected calcular() {
    let payload = {
      "interes": this.formGeneral.get('interes').value,
      "ancho": this.formGeneral.get('ancho').value,
      "alto": this.formGeneral.get('alto').value,
      "idvidriointerior": this.formGeneral.get('vidrioInternoPanio').value,
      "idvidrioexterior": this.formGeneral.get('vidrioExternoPanio').value,
      "idcamara": this.formGeneral.get('camaraPanio').value,
      "cantidad": this.formGeneral.get('cantidad').value
    };
    this._calculadoraVidrioService.calcular(payload).subscribe({
      next: (v) => {
        /* console.log('data: ', v); */
        this.formGeneral.get('detalle').setValue(v.descripcion);
        this.formGeneral.get('vidrio').setValue(v.importe);
        this.mostrarInteres = v.ingresarInteres
      },
      error: (e) => {
        console.error(e, 'error');
      },
    })
  }
  onClose() {
    this.calculadora_vidrio.push(
      {
        interes: this.formGeneral.get('interes').value,
        ancho: this.formGeneral.get('ancho').value,
        alto: this.formGeneral.get('alto').value,
        idvidriointerior: this.formGeneral.get('vidrioInternoPanio').value,
        idvidrioexterior: this.formGeneral.get('vidrioExternoPanio').value,
        idcamara: this.formGeneral.get('camaraPanio').value,
        cantidad: this.formGeneral.get('cantidad').value,
        importe: this.formGeneral.get('vidrio').value,
        descripcion: this.formGeneral.get('detalle').value
      }
    );
    this.dialogo.close(this.calculadora_vidrio);
  }

  /**
   * 
   * @param node objeto vidrio
   * @param ubi 'vidrioExterno' 'vidrioInterno' 'camara'
   * @param tipo 'Panio' 'Hoja'
   */
  recibirDatosDeVuelta(datos: any, ubi: any, tipo: any, btLabel: any) {
    const nombreFormulario = ubi + tipo;
    if (datos && datos.node) {
      this.formGeneral.get(nombreFormulario)?.setValue(datos.node.Codigo);
      this.calcular();
      // Construir el btLabel usando la ruta completa
      if (datos.fullPath && Array.isArray(datos.fullPath)) {
        btLabel = datos.fullPath
          .filter(node => node && node.Descripcion)
          .map(node => node.Descripcion)
          .join(' > ');
      } else {
        btLabel = datos.node.Descripcion || 'Selección desconocida';
      }
      switch (nombreFormulario) {
        case 'vidrioExternoPanio':
          this.btVidrioEPanio = btLabel;
          this.tooltipVidrioEPanio = btLabel;
          break;
        case 'vidrioInternoPanio':
          this.btVidrioIPanio = btLabel;
          this.tooltipVidrioIPanio = btLabel;
          break;
        case 'camaraPanio':
          this.btCamaraPanio = btLabel;
          this.tooltipCamaraPanio = btLabel;
          break;
        default:
          console.warn('Formulario no reconocido:', nombreFormulario);
      }
    } else {
      console.error('Datos recibidos inválidos:', datos);
    }
  }
}

@Component({
  selector: 'app-menu-loco',
  template: `
  <button *ngIf="isRootNode" mat-button [matMenuTriggerFor]="menu" class="w-full !rounded-lg button-custom truncate" >
    <span class="truncate">{{ trigger }}</span>
    <mat-icon *ngIf='isRootNodeOne' class="icon-size-4 ml-1 flex-shrink-0"
    [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
  </button>
  <button *ngIf="!isRootNode" mat-menu-item [matMenuTriggerFor]="menu" class="truncate" >
    {{ trigger }} 
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let node of data; let i = index">
      <button mat-menu-item>
        <app-menu-loco class="w-full"
          [trigger]="node.Descripcion"
          *ngIf="isExpandable(node); else menuItem"
               [data]="node.vidrios_data"
               [currentPath]="getCurrentPath(node)"
               (datoDeSalida)="recibirDatosDeVuelta($event)"
               ></app-menu-loco>
      </button>
      <ng-template #menuItem>
        <button mat-menu-item (click)="onDatosSalida(node)" class="truncate" >{{node.Descripcion}}</button>
      </ng-template>
    </ng-container>
  </mat-menu>
  `,
  styles: [`
    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `]
})
export class MenuComponent {
  @Input() data: any;
  @Input() trigger = "Trigger";
  @Input() isRootNode = false;
  @Input() isRootNodeOne = false;
  @Input() currentPath: any[] = [];

  @Output() datoDeSalida: EventEmitter<any> = new EventEmitter<any>();

  isExpandable(node: any): boolean {
    return node?.vidrios_data ? true : false;
  }

  getCurrentPath(node: any): any[] {
    return [...this.currentPath, node];
  }

  onDatosSalida(node) {
    const fullPath = this.getCurrentPath(node);
    this.datoDeSalida.emit({ node, fullPath });
  }

  recibirDatosDeVuelta(datos: any) {
    this.datoDeSalida.emit(datos);
  }
}