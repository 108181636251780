<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
  <button mat-icon-button *ngIf="!opened" (click)="open()">
    <mat-icon [svgIcon]="'heroicons_outline:search'" class="search-blink"></mat-icon>
    <span *ngIf="_searchService.getSearchInput().value"
      class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary-500 text-indigo-50 text-xs font-medium">
        {{ _searchService.getSearchInput().value.trim().substring(0,5)+"..." }}
      </span>
    </span>
  </button>
  <button id="filtroGeneral" mat-icon-button type="button" (click)="eventoFiltro($event,filterGral)" #filterGral>
    <mat-icon svgIcon="heroicons_solid:adjustments"></mat-icon>
  </button>
  <div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
    <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:search'"></mat-icon>
    <input #barSearchInput class="w-full h-full px-16 sm:px-18" [formControl]="_searchService.getSearchInput()"
      autocomplete="off" [placeholder]="'Search...'" (ngModelChange)="this._searchService.getSearchText().next($event)"
      (keydown)="onKeydown($event)" />
    <button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
      <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
  <div class="w-full sm:min-w-80">
    <mat-form-field class="fuse-mat-no-subscript w-full">
      <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
      <input #barSearchInput class="w-full h-full px-16 sm:px-18" [formControl]="_searchService.getSearchInput()"
        autocomplete="off" [placeholder]="'Search...'"
        (ngModelChange)="this._searchService.getSearchText().next($event)" (keydown)="onKeydown($event)" />
    </mat-form-field>
  </div>
</ng-container>