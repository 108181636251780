import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthLocalService } from "../auth-local.service";
import { IUser } from "@core/models/auth/user";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IRoutesPermissions } from "./permissions";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private storageService: AuthLocalService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.storageService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(["/sign-in"], {
      queryParams: {
        return: state.url,
      },
    });
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const user: IUser = this.storageService.getCurrentUser();
    const ruta = childRoute.parent.url.toString();
    if (this.storageService.isAdmin()) return true;
    let str: number;

    switch (ruta) {
      case "": {
        if (user.vendedor) {
          // si tiene un vendedor asignado puede ir al inicio, sino redirige
          return true;
        }
        this.navegar(user);

        break;
      }
      // VENTAS
      case "calendario": {
        str = user.permisos.find((x) => x === IRoutesPermissions.calendario); // 101
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "seguimiento-profesionales": {
        str = user.permisos.find((x) => x === IRoutesPermissions.seguimientoProfesionales); // 103
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "seguimiento-obras": {
        str = user.permisos.find((x) => x === IRoutesPermissions.seguimientoObras); // 102
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "cotizador": {
        str = user.permisos.find((x) => x === IRoutesPermissions.cotizador); // 104
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "book": {
        str = user.permisos.find((x) => x === IRoutesPermissions.book); // 105
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "mediciones": {
        str = user.permisos.find((x) => x === IRoutesPermissions.mediciones); // 115
        if (str) {
          return true;
        }
        this.navegar(user);
      }
      case "carteles-obra": {
        str = user.permisos.find((x) => x === IRoutesPermissions.cartelesObra); // 115
        if (str) {
          return true;
        }
        this.navegar(user);
      }
      case "mis-clientes": {
        str = user.permisos.find((x) => x === IRoutesPermissions.misClientes);
        if (str) {
          return true;
        }
        this.navegar(user);
      }
      case "oportunidades":
      case "contactos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.oportunidades);
        if (str) {
          return true;
        }
        this.navegar(user);
      }
      case "mapa": {
        str = user.permisos.find((x) => x === IRoutesPermissions.mapa);
        if (str) {
          return true;
        }
        this.navegar(user);
      }
      // TALLER
      case "remitos-aasa": {
        str = user.permisos.find((x) => x === IRoutesPermissions.remitosAasa); // 107
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "planta-pintura": {
        str = user.permisos.find((x) => x === IRoutesPermissions.remitosAasa); // 107
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "trazabilidad-pedidos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.trazabilidadPedidos);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "trazabilidad": {
        str = user.permisos.find((x) => x === IRoutesPermissions.taller); // 106
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "devoluciones": {
        str = user.permisos.find((x) => x === IRoutesPermissions.devoluciones);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "perfiles-pendientes": {
        str = user.permisos.find((x) => x === IRoutesPermissions.perfilesPendientes);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // REPORTES
      case "planilla-sueldo-banco": {
        str = user.permisos.find((x) => x === IRoutesPermissions.planilla_sueldo);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "kilos-aluminio-proveedor": {
        str = user.permisos.find((x) => x === IRoutesPermissions.kilos_aluminio);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "informe-retenciones": {
        str = user.permisos.find((x) => x === IRoutesPermissions.informe_retenciones);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "facturacion-punto-venta": {
        str = user.permisos.find((x) => x === IRoutesPermissions.facturacion_punto_venta);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // MATERIALES
      case "demora-oc": {
        str = user.permisos.find((x) => x === IRoutesPermissions.demoraOc);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "inventario": {
        str = user.permisos.find((x) => x === IRoutesPermissions.inventario);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "remitos-internos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.remitos_internos);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "stock-panol": {
        str = user.permisos.find((x) => x === IRoutesPermissions.stock_panol);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "pedidos":
      case "articulos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.pedidos);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // MANTENIMIENTO
      case "vehiculos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.vehiculos);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // COMUNICACION
      case "calendario": {
        str = user.permisos.find((x) => x === IRoutesPermissions.rrssCalendario);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "book": {
        str = user.permisos.find((x) => x === IRoutesPermissions.cargaBook); // 105
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "noticias": {
        str = user.permisos.find((x) => x === IRoutesPermissions.noticiasWeb); // 108
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "competencia": {
        str = user.permisos.find((x) => x === IRoutesPermissions.competencia); // 109
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "mailing": {
        str = user.permisos.find((x) => x === IRoutesPermissions.mailing); // 110
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // RRHH
      case "busquedas": {
        str = user.permisos.find((x) => x === IRoutesPermissions.busquedas); // 129
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "sueldos": {
        str = user.permisos.find((x) => x === IRoutesPermissions.sueldosDigitales); // 129
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // GERENCIA
      case "personal": {
        str = user.permisos.find((x) => x === IRoutesPermissions.listadoPersonal); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "estadisticas": {
        str = user.permisos.find((x) => x === IRoutesPermissions.estadisticas); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "postulantes": {
        str = user.permisos.find((x) => x === IRoutesPermissions.postulantes); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // GENERAL
      case "usuarios": {
        str = user.permisos.find((x) => x === IRoutesPermissions.usuarios); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "configuracion": {
        str = user.permisos.find((x) => x === IRoutesPermissions.configuracion); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "panel": {
        str = user.permisos.find((x) => x === IRoutesPermissions.panelControl); // 111
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "listado-whatsapp": {
        str = user.permisos.find((x) => x === IRoutesPermissions.listadoWhatsapp);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      // CONFIGURACION PRECIOS
      case "reportes":
      case "puertas-interior":
      case "cortinas-interior":
      case "cortinas-aluminio": {
        str = user.permisos.find((x) => x === IRoutesPermissions.configuracionPrecios);
        if (str) {
          return true;
        }
        this.navegar(user);

        break;
      }
      case "common":
      case "404":
      case "500":
      case "maintenance": {
        return true;
      }
      default: {
        this.navegar(user);
      }
    }
  }
  /**
   * Si no puede acceder a un recurso se redirige al primero disponible para su perfil
   *
   * @param {IUser} usuario
   * @memberof UserRoleGuard
   */
  navegar(usuario: IUser) {
    switch (usuario.permisos[0]) {
      // ventas
      case IRoutesPermissions.calendario:
      case IRoutesPermissions.seguimientoProfesionales:
      case IRoutesPermissions.seguimientoObras:
      case IRoutesPermissions.cotizador:
      case IRoutesPermissions.book:
      case IRoutesPermissions.mediciones:
      case IRoutesPermissions.cartelesObra:
      case IRoutesPermissions.misClientes:
      case IRoutesPermissions.clientes:
      case IRoutesPermissions.mapa:
      case IRoutesPermissions.configuracionPrecios:
        this.router.navigate(["/ventas"]);
        break;
      case IRoutesPermissions.calculadora: {
        this.router.navigate(["/calculadora"]);
        break;
      }
      // taller
      case IRoutesPermissions.trazabilidadPedidos:
      case IRoutesPermissions.devoluciones:
      case IRoutesPermissions.taller:
      case IRoutesPermissions.remitosAasa:
      case IRoutesPermissions.perfilesPendientes: {
        this.router.navigate(["taller"]);
        break;
      }
      // materiales
      case IRoutesPermissions.pedidos:
        this.router.navigate(["/mantenimiento"]);
      // comunicacion
      case IRoutesPermissions.rrssCalendario:
      case IRoutesPermissions.noticiasWeb:
      case IRoutesPermissions.competencia:
      case IRoutesPermissions.mailing:
      case IRoutesPermissions.cargaBook: {
        this.router.navigate(["/comunicacion"]);
        break;
      }
      // RRHH
      case IRoutesPermissions.busquedas:
      case IRoutesPermissions.sueldosDigitales:
        this.router.navigate(["/rrhh"]);
        break;

      // Gerencia
      case IRoutesPermissions.estadisticas:
      case IRoutesPermissions.listadoPersonal:
      case IRoutesPermissions.postulantes:
        this.router.navigate(["/gerencia"]);
        break;
      // General
      case IRoutesPermissions.usuarios:
      case IRoutesPermissions.configuracion:
      case IRoutesPermissions.panelControl:
        this.router.navigate(["/general"]);
        break;

      case IRoutesPermissions.listadoWhatsapp: {
        this.router.navigate(["/"]);
        break;
      }
      // Configuracion Precios
      case IRoutesPermissions.configuracionPrecios: {
        this.router.navigate(["/configuracion-precios"]);
      }
      default: {
        this.storageService.signOut();
        this.router.navigate(["/sign-in"]);
      }
    }
  }
}
