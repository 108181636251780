import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { NotificationsService } from "@shared/components/notifications/notifications.service";
import { ProspectosRootService } from "@shared/services/app/prospectos-root.service";
import { forkJoin } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AppResolvers implements Resolve<any> {
  constructor(
    private _notificacionesService: NotificationsService,
    private _prospectosRootService: ProspectosRootService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /* console.log('AppResolvers') */
    return forkJoin([
      this._notificacionesService.getAll(),
      /* this._prospectosRootService.getAll(), */
    ]);
  }
}
