import { IModulesPermissions, IRoutesPermissions } from "@core/auth/guards/permissions";
import { IUser } from "@core/models/auth/user";
import { ProspectosService } from "@features/ventas/prospectos/services/propectos.service";
import { FuseNavigationItem } from "@fuse/components/navigation";
import { environment } from "environments/environment";
import { map } from "rxjs";

import * as actions from "@features/ventas/prospectos/store/prospectos.actions";
import { Store } from "@ngrx/store";

const getModuleActive = (user: IUser, module: number[]) => {
  return user ? !user.permisos?.find((permiso) => module.includes(permiso)) : true;
};

const getChildActive = (user: IUser, child: number) => {
  return user ? !user.permisos?.includes(child) : true;
};

const getRol = (user: IUser, hiddens: any) => {
  /*  let hidden = false;
   user.roles.forEach(rol => {
     hiddens.forEach(rolHidden => {
       hidden = (rol == (rolHidden?.id? rolHidden.id:rolHidden).toString() ? true : false);
     });
   });
   return hidden; */
  return user.roles.find((rol) => {
    hiddens.find((rolHidden) => {
      rol == (rolHidden?.id ? rolHidden.id : rolHidden).toString() ? true : false;
    });
  })
    ? true
    : false;
};

/* function getContadores(contador: string){
  let store: Store;
  store.dispatch(new actions.ContadoresPending());
  return contador;
}; */

export function getDefaultNavigation(user: IUser): FuseNavigationItem[] {
  return [
    /*  {
      id: 'home',
      title: 'Inicio',
      type: 'basic',
      icon: 'mat_outline:home',
      link: '/',
      hidden: () => false,
    }, */
    {
      id: "clientes",
      title: "Clientes",
      type: "basic",
      icon: "mat_outline:person",
      link: "/clientes/clientes",
      hidden: () => getModuleActive(user, IModulesPermissions.ventas),
    },
    {
      id: "ventas",
      title: "Comercial",
      type: "collapsable",
      icon: "mat_outline:storefront",
      hidden: () => getModuleActive(user, IModulesPermissions.ventas),
      children: [
        {
          id: "ventas.profesionales",
          title: "Seguimiento de Profesionales",
          type: "basic",
          link: "/ventas/seguimiento-profesionales",
          hidden: () => getChildActive(user, IRoutesPermissions.seguimientoProfesionales),
        },
        {
          id: "ventas.obras",
          title: "Seguimiento de Obras",
          type: "basic",
          link: "/ventas/seguimiento-obras",
          hidden: () => getChildActive(user, IRoutesPermissions.seguimientoObras),
        },
        {
          id: "ventas.oportunidades",
          title: "Oportunidades",
          type: "basic",
          link: "/ventas/oportunidades",
          hidden: () => getChildActive(user, IRoutesPermissions.oportunidades),
        },
        {
          id: "ventas.calendario",
          title: "Calendario",
          type: "basic",
          link: "/ventas/calendario",
          hidden: () => getChildActive(user, IRoutesPermissions.calendario),
        },
        {
          id: "ventas.mediciones",
          title: "Mediciones",
          type: "basic",
          link: "/ventas/mediciones",
          hidden: () => getChildActive(user, IRoutesPermissions.mediciones),
        },
        {
          id: "ventas.contactos",
          title: "Contactos",
          type: "basic",
          link: "/ventas/contactos",
          hidden: () => getChildActive(user, IRoutesPermissions.clientes),
        },
        {
          id: "ventas.book",
          title: "Book de Fotos",
          type: "basic",
          link: "/ventas/book",
          hidden: () => getChildActive(user, IRoutesPermissions.book),
        },
        {
          id: "ventas.carteles",
          title: "Carteles de Obra",
          type: "basic",
          link: "/ventas/carteles-obra",
          hidden: () => getChildActive(user, IRoutesPermissions.cartelesObra),
        },
      ],
    },
    {
      id: "calculadora",
      title: "Calculadora",
      type: "collapsable",
      icon: "mat_outline:calculate",
      link: "/calculadora",
      hidden: () => getModuleActive(user, IModulesPermissions.calculadora),
      children: [
        {
          id: "calculadora.abertura-lista",
          title: "Abertura de lista",
          type: "basic",
          link: "/calculadora/abertura-lista/" + "CALCULAR",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
        {
          id: "calculadora.save-abertura-editable",
          title: "Abertura Editable",
          type: "basic",
          link: "/calculadora/save-abertura-editable/" + "CALCULAR",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
        {
          id: "calculadora.puerta-lista",
          title: "Puerta de lista",
          type: "basic",
          link: "/calculadora/puerta-lista/" + "CALCULAR",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
        {
          id: "calculadora.puerta-chapa",
          title: "Puerta de Chapa",
          type: "basic",
          link: "/calculadora/puerta-chapa/" + "CALCULAR/" + "PUERTA",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
        {
          id: "calculadora.porton-chapa",
          title: "Portón de Chapa",
          type: "basic",
          link: "/calculadora/puerta-chapa/" + "CALCULAR/" + "PORTON",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
        {
          id: "calculadora.puerta-interior",
          title: "Puerta Interior",
          type: "basic",
          link: "/calculadora/puerta-interior/" + "CALCULAR",
          hidden: () => getChildActive(user, IRoutesPermissions.calculadora),
        },
      ],
    },
    {
      id: "taller",
      title: "Taller",
      type: "collapsable",
      icon: "mat_outline:apartment",
      hidden: () => getModuleActive(user, IModulesPermissions.taller),
      children: [
        {
          id: "taller.taller",
          title: "Trazabilidad - OT",
          type: "basic",
          link: "/taller/trazabilidad",
          hidden: () => getChildActive(user, IRoutesPermissions.taller),
        },
        {
          id: "taller.planta",
          title: "Proceso Planta de Pintura",
          type: "basic",
          link: "/taller/planta-pintura",
          hidden: () => getChildActive(user, IRoutesPermissions.procesoPlantaPintura),
        },
        // {
        //   id: 'taller.perfiles',
        //   title: 'Perfiles Pendientes',
        //   type: 'basic',
        //   link: '/taller/perfiles-pendientes',
        //   hidden: () => getChildActive(user, IRoutesPermissions.perfilesPendientes),
        // },
        // {
        //   id: 'taller.pedidos',
        //   title: 'Trazabilidad de Pedidos',
        //   type: 'basic',
        //   link: '/taller/trazabilidad-pedidos',
        //   hidden: () => getChildActive(user, IRoutesPermissions.trazabilidadPedidos),
        // },

        // {
        //   id: 'taller.devoluciones',
        //   title: 'Devoluciones',
        //   type: 'basic',
        //   link: '/taller/devoluciones',
        //   hidden: () => getChildActive(user, IRoutesPermissions.devoluciones),
        // },
        {
          id: "taller.remitos",
          title: "Remitos AASA",
          type: "basic",
          link: "/taller/remitos-aasa",
          hidden: () => getChildActive(user, IRoutesPermissions.remitosAasa),
        },
      ],
    },
    {
      id: "reportes",
      title: "Reportes",
      type: "collapsable",
      icon: "mat_outline:assignment_ind",
      hidden: () => getModuleActive(user, IModulesPermissions.reportes),
      children: [
        {
          id: "planilla-sueldo-banco",
          title: "Planilla de Sueldos para Banco",
          type: "basic",
          link: "/reportes/planilla-sueldo-banco",
          hidden: () => getChildActive(user, IRoutesPermissions.planilla_sueldo),
        },
        {
          id: "informe-retenciones",
          title: "Informe de Retenciones",
          type: "basic",
          link: "/reportes/informe-retenciones",
          hidden: () => getChildActive(user, IRoutesPermissions.informe_retenciones),
        },
        {
          id: "facturacion-punto-venta",
          title: "Facturación por Punto de Venta",
          type: "basic",
          link: "/reportes/facturacion-punto-venta",
          hidden: () => getChildActive(user, IRoutesPermissions.facturacion_punto_venta),
        },
        {
          id: "kilos-aluminio-proveedor",
          title: "Kilos de Aluminio por Proveedor",
          type: "basic",
          link: "/reportes/kilos-aluminio-proveedor",
          hidden: () => getChildActive(user, IRoutesPermissions.kilos_aluminio),
        },
      ],
    },
    {
      id: "materiales",
      title: "Materiales",
      type: "collapsable",
      icon: "mat_outline:apps",
      hidden: () => getModuleActive(user, IModulesPermissions.materiales),
      children: [
        {
          id: "materiales.articulos",
          title: "Artículos",
          type: "basic",
          link: "/materiales/articulos/listado",
          hidden: () => getChildActive(user, IRoutesPermissions.articulos),
        },
        {
          id: "materiales.stock-panol",
          title: "Stock por Pañol",
          type: "basic",
          link: "/materiales/stock-panol",
          hidden: () => getChildActive(user, IRoutesPermissions.stock_panol),
        },
        {
          id: "materiales.pedidos",
          title: "Pedido de Materiales",
          type: "basic",
          link: "/materiales/pedidos",
          hidden: () => getChildActive(user, IRoutesPermissions.pedidos),
        },
        {
          id: "materiales.demora-oc",
          title: "Demora de Órdenes de Compra",
          type: "basic",
          link: "/materiales/demora-oc",
          hidden: () => getChildActive(user, IRoutesPermissions.demoraOc),
        },
        {
          id: "materiales.inventario",
          title: "Inventario",
          type: "basic",
          link: "/materiales/inventario",
          hidden: () => getChildActive(user, IRoutesPermissions.inventario),
        },
        {
          id: "materiales.remitos-internos",
          title: "Remitos Internos",
          type: "basic",
          link: "/materiales/remitos-internos",
          hidden: () => getChildActive(user, IRoutesPermissions.remitos_internos),
        },
      ],
    },
    // {
    //   id: 'mantenimiento',
    //   title: 'Mantenimiento',
    //   type: 'collapsable',
    //   icon: 'heroicons_outline:truck',
    //   hidden: () => getModuleActive(user, IModulesPermissions.mantenimiento),
    //   children: [
    //     {
    //       id: 'mantenimiento.vehiculos',
    //       title: 'Vehículos',
    //       type: 'basic',
    //       link: '/mantenimiento/vehiculos',
    //       hidden: () => getChildActive(user, IRoutesPermissions.vehiculos),
    //     },
    //   ],
    // },
    // {
    //   id: 'comunicacion',
    //   title: 'Comunicación',
    //   type: 'collapsable',
    //   icon: 'heroicons_outline:rss',
    //   hidden: () => getModuleActive(user, IModulesPermissions.comunicacion),
    //   children: [
    //     {
    //       id: 'comunicacion.calendario',
    //       title: 'RSS Calendario',
    //       type: 'basic',
    //       link: '/comunicacion/calendario',
    //       hidden: () => getChildActive(user, IRoutesPermissions.rrssCalendario),
    //     },
    //     {
    //       id: 'comunicacion.book',
    //       title: 'Carga Book de Fotos',
    //       type: 'basic',
    //       link: '/comunicacion/book',
    //       hidden: () => getChildActive(user, IRoutesPermissions.cargaBook),
    //     },
    //     {
    //       id: 'comunicacion.noticias',
    //       title: 'Noticias Web',
    //       type: 'basic',
    //       link: '/comunicacion/noticias',
    //       hidden: () => getChildActive(user, IRoutesPermissions.noticiasWeb),
    //     },
    //   ],
    // },
    {
      id: "rrhh",
      title: "RRHH",
      type: "collapsable",
      icon: "mat_outline:groups",
      hidden: () => getModuleActive(user, IModulesPermissions.rrhh),
      children: [
        {
          id: "rrhh.busquedas",
          title: "Búsquedas",
          type: "basic",
          link: "/rrhh/busquedas",
          hidden: () => getChildActive(user, IRoutesPermissions.busquedas),
        },
        {
          id: "rrhh.sueldos",
          title: "Sueldos Digital",
          type: "basic",
          link: "/rrhh/sueldos",
          hidden: () => getChildActive(user, IRoutesPermissions.sueldosDigitales),
        },
      ],
    },
    // {
    //   id: 'general',
    //   title: 'General',
    //   type: 'collapsable',
    //   icon: 'heroicons_outline:clipboard-check',
    //   hidden: () => getModuleActive(user, IModulesPermissions.general),
    //   children: [
    //     {
    //       id: 'general.usuarios',
    //       title: 'Usuarios',
    //       type: 'basic',
    //       link: '/general/usuarios',
    //       hidden: () => getChildActive(user, IRoutesPermissions.usuarios),
    //     },
    //     {
    //       id: 'general.configuracion',
    //       title: 'Configuración',
    //       type: 'basic',
    //       link: '/general/configuracion',
    //       hidden: () => getChildActive(user, IRoutesPermissions.configuracion),
    //     },
    //     {
    //       id: 'general.panel',
    //       title: 'Panel de Control',
    //       type: 'basic',
    //       link: '/general/panel',
    //       hidden: () => getChildActive(user, IRoutesPermissions.panelControl),
    //     },
    //   ],
    // },
    // {
    //   id: 'gerencia',
    //   title: 'Gerencia',
    //   type: 'collapsable',
    //   icon: 'heroicons_outline:users',
    //   hidden: () => getModuleActive(user, IModulesPermissions.gerencia),
    //   children: [
    //     {
    //       id: 'general.estadistica',
    //       title: 'Estadística',
    //       type: 'basic',
    //       link: '/gerencia/estadisticas',
    //       hidden: () => getChildActive(user, IRoutesPermissions.estadisticas),
    //     },
    //   ],
    // },
    {
      id: "configuraciones",
      title: "Configuraciones",
      type: "collapsable",
      icon: "mat_outline:settings",
      hidden: () => getModuleActive(user, IModulesPermissions.configuracionPrecios),
      children: [
        {
          id: "configuraciones.precios",
          title: "Reportes de Precios",
          type: "basic",
          link: "/configuraciones/precios",
        },
        {
          id: "configuraciones.zonas",
          title: "Zonas",
          type: "basic",
          link: "/configuraciones/zonas",
        },
      ],
    },
    {
      id: "indicadores",
      title: "Indicadores",
      type: "collapsable",
      icon: "mat_outline:pie_chart",
      hidden: () => getModuleActive(user, IModulesPermissions.gerencia),
      children: [
        {
          id: "indicadores.ventas",
          title: "Ventas",
          type: "basic",
          link: "/indicadores/ventas",
        },
        {
          id: "indicadores.prospectos",
          title: "Prospectos",
          type: "basic",
          link: "/indicadores/prospectos",
        },
        {
          id: "indicadores.eficiencia-ventas",
          title: "Eficiencia de Ventas",
          type: "basic",
          link: "/indicadores/eficiencia-ventas",
        },
        {
          id: "indicadores.visitas",
          title: "Visitas",
          type: "basic",
          link: "/indicadores/visitas",
        },
        {
          id: "indicadores.analytics",
          title: "Analytics",
          type: "basic",
          link: "/indicadores/analytics",
        },
        {
          id: "indicadores.carteles-no-colocados",
          title: "Carteles No Colocados",
          type: "basic",
          link: "/indicadores/carteles-no-colocados",
        },
        {
          id: "indicadores.carteles-colocados",
          title: "Carteles Colocados",
          type: "basic",
          link: "/indicadores/carteles-colocados",
        },
        {
          id: "indicadores.carteles-medicion",
          title: "Estado de Medición",
          type: "basic",
          link: "/indicadores/estado-medicion",
        },
        {
          id: "indicadores.avance-alberdi",
          title: "Avance Alberdi",
          type: "basic",
          link: "/indicadores/avance-alberdi",
        },
        {
          id: "indicadores.avance-showroom",
          title: "Avance Showroom",
          type: "basic",
          link: "/indicadores/avance-showroom",
        },
      ],
    },
  ];
}
