
import { ComponentType } from "@angular/cdk/portal";
import { ElementRef, Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { Router } from "@angular/router";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private searchText = new Subject<string>();
  private search: FormControl = new FormControl();
  private filterEvent = new Subject();
  private filterOrigin: MatButton;
  /* private filterComponent: ComponentType<any>;
  private filterData: any; */

  /**
   * Getter for searchText
   */
  getSearchText(): Subject<string> {
    return this.searchText;
  }

  /**
   * Setter for searchText
   * @param textoBuscar Texto de input general de búsqueda
   */
  setSearchText(textoBuscar: Subject<string>): void {
    this.searchText = textoBuscar;
  }

  getSearchInput(): FormControl {
    return this.search;
  }

  setSearchInput(searchInput: FormControl): void {
    this.search = searchInput;
  }

  unsubscribe(): void {
    /* console.log("unsuscribe") */
    this.searchText.unsubscribe();
    this.searchText = new Subject<string>();
    this.search.setValue('');
    this.search.enable();
    /* console.log("unsuscribe 2") */
    this.filterEvent.unsubscribe();
    this.filterEvent = new Subject();
    /* console.log("unsuscribe listos") */
  }

  setFilterEvent(filterEvent: Subject<any>): void {
    this.filterEvent = filterEvent;
  }

  getFilterEvent(): Subject<any> {
    return this.filterEvent;
  }

  getFilterOrigin(): MatButton {
    return this.filterOrigin;
  }

  setFilterOrigin(filterOrigin: MatButton): void {
    this.filterOrigin = filterOrigin;
  }
  /*   setFilterComponent(filterComponent: ComponentType<any>): void {
      this.filterComponent = filterComponent;
    }
  
    getFilterComponent(): ComponentType<any> {
      return this.filterComponent;
    }
  
    setFilterData(filterData: any): void {
      this.filterData = filterData;
    }
  
    getFilterData(): any {
      return this.filterData;
    } */
}
