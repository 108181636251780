import {
  Observable,
  ReplaySubject,
  map,
  switchMap,
  take,
  tap,
  forkJoin,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAlerta, Notification } from "./notifications.types";
import { environment } from "environments/environment";
import { AuthLocalService } from "@core/auth/auth-local.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<
    Notification[]
  >(1);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _authLocalService: AuthLocalService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all notifications
   */
  getAll(): Observable<Notification[]> {
    return this._httpClient
      .get<Notification[]>(
        `${environment.api.common.notificaciones}?filter[receptor]=${this._authLocalService.getCurrentUser().codigo
        }&filter[leido]=0&sort=-fecha`
      )
      .pipe(
        tap((notifications) => {
          this._notifications.next(notifications);
        })
      );
  }

  /**
   * Create a notification
   *
   * @param notification
   */
  create(notification: Notification): Observable<Notification> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .post<Notification>("api/common/notifications", { notification })
          .pipe(
            map((newNotification) => {
              // Update the notifications with the new notification
              this._notifications.next([...notifications, newNotification]);

              // Return the new notification from observable
              return newNotification;
            })
          )
      )
    );
  }

  /**
   * Update the notification
   *
   * @param codigo
   * @param notification
   */
  update(notification: IAlerta): Observable<any> {
    // return this._httpClient.put(
    //   `${environment.api.common.notificaciones}/${notification.codigo}`,
    //   notification
    // ).pipe(switchMap((notification)))
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .put(
            `${environment.api.common.update_notificacion}/${notification.Codigo}`,
            notification
          )
          .pipe(
            map((updatedNotification: IAlerta) => {
              // Find the index of the updated notification
              const index = notifications.findIndex(
                (n) => n.codigo === notification.Codigo
              );
              notifications.splice(index, 1);

              // Update the notifications
              this._notifications.next(notifications);

              // Return the updated notification
              return updatedNotification;
            })
          )
      )
    );
  }

  /**
   * Delete the notification
   *
   * @param codigo
   */
  delete(codigo: number): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .delete<boolean>("api/common/notifications", { params: { codigo } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted notification
              const index = notifications.findIndex(
                (item) => item.codigo === codigo
              );

              // Delete the notification
              notifications.splice(index, 1);

              // Update the notifications
              this._notifications.next(notifications);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): Observable<any> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        forkJoin(
          notifications.map((notification) =>
            this._httpClient.put(
              `${environment.api.common.update_notificacion}/${notification.codigo}`,
              {
                Alerta: notification.alerta,
                Codigo: notification.codigo,
                Leido: 1,
                Usuario: notification.receptor,
              }
            )
          )
        ).pipe(
          map(() => {
            // Go through all notifications and set them as read
            notifications = [];

            // Update the notifications
            this._notifications.next(notifications);

            // Return the updated status
            return true;
          })
        )
      )
    );
  }
}
