import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Subject, takeUntil } from "rxjs";
import { MatButton } from "@angular/material/button";
import { IAlerta, Notification, NotificationType } from "./notifications.types";
import { NotificationsService } from "./notifications.service";
import { TemplatePortal } from "@angular/cdk/portal";

@Component({
  selector: "notifications",
  templateUrl: "./notifications.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "notifications",
  styles: [`
    .bell-blink {
      animation: shadow-animation 2s infinite;
    }

    @keyframes shadow-animation {
      0% {
        box-shadow: none;
      }
      50% {
        box-shadow: 0 0 15px rgba(239, 68, 68, 0.596);
      }
      100% {
        box-shadow: none;
      }
    }
  `]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild("notificationsOrigin") private _notificationsOrigin: MatButton;
  @ViewChild("notificationsPanel")
  private _notificationsPanel: TemplateRef<any>;

  notifications: Notification[];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this.cargarNotificaciones();
  }

  cargarNotificaciones() {
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        // Load the notifications
        this.notifications = notifications;

        // Calculate the unread count
        this._calculateUnreadCount();

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    this._notificationsService.markAllAsRead().subscribe({
      next: (data) => {
        this._notificationsService.getAll().subscribe({
          next: (data) => {
            this.cargarNotificaciones();
          },
        })
      }
    })

  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification: Notification): void {
    // Toggle the read status
    const updateNotification: IAlerta = {
      Alerta: notification.alerta,
      Codigo: notification.codigo,
      Leido: 1,
      Usuario: notification.receptor,
    };

    // Update the notification
    this._notificationsService.update(updateNotification).subscribe();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.codigo || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: "fuse-backdrop-on-mobile",
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top",
          },
          {
            originX: "start",
            originY: "top",
            overlayX: "start",
            overlayY: "bottom",
          },
          {
            originX: "end",
            originY: "bottom",
            overlayX: "end",
            overlayY: "top",
          },
          {
            originX: "end",
            originY: "top",
            overlayX: "end",
            overlayY: "bottom",
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(
        (notification) => !notification.read
      ).length;
    }

    this.unreadCount = count;
  }

  getNotificationTitle(notification: Notification): string {
    let notif = NotificationType.find((notif) => notif.tipo === notification.tipo);
    return notif ? notif.text : "Alerta genérica";
  }

  getNotificationIcon(notification: Notification): string {
    let notif = NotificationType.find((notif) => notif.tipo === notification.tipo);
    return notif ? notif.icon : "mat_solid:message";
  }

  getBlink() {
    return this.unreadCount > 0 ? "bell-blink" : "bell"
  }
}
