import { ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer, routerReducer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}
export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
}
export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    return { url, queryParams, params };
  }
}
