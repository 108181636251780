import { Component, ContentChildren, Input, ViewChild, QueryList, ElementRef, AfterViewInit, OnChanges, SimpleChanges, ViewChildren, Output, EventEmitter } from '@angular/core';
import { CarouselItemDirective } from '@shared/directives/carousel';
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';

@Component({
  selector: 'aasa-carousel',
  exportAs: 'aasa-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit, OnChanges {
  @ContentChildren(CarouselItemDirective) slides: QueryList<CarouselItemDirective>;
  @ViewChildren('carouselItemElement', {read: ElementRef}) slidesElements: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel: ElementRef;
  @Input() timing = '450ms ease-in';
  @Input() showControls = false;
  @Input() activeId: string;
  @Output() onActiveItem = new EventEmitter<number>;
  private player: AnimationPlayer;
  private itemWidth: number;
  private currentSlide = 0;
  carouselWrapperStyle = {};
  width: number;
  constructor(private builder: AnimationBuilder) { 
    this.width = window.innerWidth * 3;
  }

  next() {
    if (this.currentSlide + 1 === this.slides.length) return;
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.onActiveItem.emit(this.currentSlide);
  }

  private buildAnimation(offset: number) {
    return this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
    ]);
  }

  prev() {
    if (this.currentSlide === 0) return;

    this.currentSlide = ((this.currentSlide - 1) + this.slides.length) % this.slides.length;
    const offset = this.currentSlide * this.itemWidth;

    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.onActiveItem.emit(this.currentSlide);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeId) {
      changes.activeId.currentValue;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.itemWidth = this.slidesElements.first.nativeElement.getBoundingClientRect().width;
      this.carouselWrapperStyle = {
        width: `${this.itemWidth}px`
      }
      this.currentSlide = this.activeId ? this.slidesElements.map(s => s.nativeElement.firstChild.id).indexOf(this.activeId) : 0;
      this.carousel.nativeElement.style.transform = `translateX(-${this.currentSlide * this.itemWidth}px)`;
      this.onActiveItem.emit(this.currentSlide);
    }, 100);
  }

}
