import { HttpClient } from '@angular/common/http';
import { IUser } from '@core/models/auth/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '@core/models/auth/session';

@Injectable()
export class AuthLocalService {
  private currentSession: Session = null;
  constructor(private httpClient: HttpClient, private router: Router) {
    this.currentSession = this.getCurrentSession();
  }

  public setCurrentSession(session: Session) {
    this.currentSession = session;
    this.currentSession.exp = new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).getTime();
    localStorage.setItem('user', JSON.stringify(this.currentSession));
  }

  public loadSessionData(): Session {
    return localStorage.getItem('user')
      ? <Session>JSON.parse(localStorage.getItem('user'))
      : null;
  }

  public getCurrentSession(): Session {
    return JSON.parse(localStorage.getItem('user'));
  }

  public removeCurrentSession(): void {
    localStorage.removeItem('user');
    this.currentSession = null;
  }

  public isAuthenticated(): boolean {
    return !!(
      this.getCurrentToken() && this.getExpiration() > new Date().getTime()
    );
  }

  private getExpiration(): number {
    return this.getCurrentSession().exp;
  }

  public getCurrentToken(): string {
    const session = this.getCurrentSession();
    return session && session.token ? session.token : null;
  }

  public getCurrentUser(): IUser {
    const session: Session = this.getCurrentSession();
    return session && session.user ? session.user : null;
  }

  public isAdmin(): boolean {
    return this.currentSession.user.permisos.some(p => p === 99);
  }

  public signOut() {
    this.removeCurrentSession();
    this.router.navigate(['/sign-in']);
  }

  setLayout(layout: string) {
    let session: Session = JSON.parse(localStorage.getItem('user'));
    session.user.layout = layout;
    this.setCurrentSession(session);
  }

  setTheme(theme: string) {
    let session: Session = JSON.parse(localStorage.getItem('user'));
    session.user.theme = theme;
    this.setCurrentSession(session);
  }

  setScheme(scheme: string) {
    let session: Session = JSON.parse(localStorage.getItem('user'));
    session.user.scheme = scheme;
    this.setCurrentSession(session);
  }
}
