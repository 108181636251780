<aasa-spinner *ngIf="mostrarSpinner" message="Calculadora" class="w-full"></aasa-spinner>
<div class="w-full h-full" *ngIf="!mostrarSpinner">
  <div mat-dialog-header class="w-full flex justify-between items-center text-white py-2 px-6 bg-yellow_aberturas-500">
    <span class="font-bold text-3xl">Calculadora de Vidrios</span>
    <button mat-icon-button (click)="dialogo.close()">
      <mat-icon class="text-white">close</mat-icon>
    </button>
  </div>
  <form [formGroup]='formGeneral' class="w-full p-8 overscroll-none">
    <div class="w-full flex flex-row">
      <div class="w-3/12 mt-3">
        <mat-radio-group class="w-full flex" formControlName="simpleVPanio">
          <div class="w-1/2 flex flex-col items-center">
            <mat-radio-button [checked]="this.formGeneral.get('simpleVPanio').value" value='true'>
            </mat-radio-button>
            <span class="font-bold mt-4">Simple</span>
          </div>
          <div class="w-1/2 flex flex-col items-center">
            <mat-radio-button [checked]="this.formGeneral.get('simpleVPanio').value === 'false'" value='false'>
            </mat-radio-button>
            <span class="font-bold mt-4">DVH</span>
          </div>
        </mat-radio-group>
      </div>
      <div class="flex w-full items-center  mt-3">
        <div class="w-3/12 px-2" *ngIf="this.formGeneral.get('simpleVPanio').value === 'false' ">
          <div class=" text-left mb-1">Vidrio Externo</div>
          <div class=" bg-white rounded-lg border border-zinc-200 mb-1" [matTooltip]="tooltipVidrioEPanio">
            <app-menu-loco [trigger]="btVidrioEPanio" [data]="categorias" class="w-full" [isRootNode]="true"
              [isRootNodeOne]="true"
              (datoDeSalida)="recibirDatosDeVuelta($event, 'vidrioExterno', 'Panio', btVidrioEPanio)">
            </app-menu-loco>
          </div>
        </div>
        <div class="w-3/12 px-2" *ngIf="this.formGeneral.get('simpleVPanio').value === 'false' ">
          <div class=" text-left mb-1">Cámara</div>
          <div class=" bg-white rounded-lg border border-zinc-200 mb-1" [matTooltip]="tooltipCamaraPanio">
            <app-menu-loco [trigger]="btCamaraPanio" [data]="camaras" class="w-full" [isRootNode]="true"
              [isRootNodeOne]="true" (datoDeSalida)="recibirDatosDeVuelta($event, 'camara', 'Panio', btCamaraPanio)">
            </app-menu-loco>
          </div>
        </div>
        <div class="w-3/12 px-2">
          <div class=" text-left mb-1">Vidrio Interno</div>
          <div class=" bg-white rounded-lg border border-zinc-200 mb-1" [matTooltip]="tooltipVidrioIPanio">
            <app-menu-loco [trigger]="btVidrioIPanio" [data]="categorias" class="w-full" [isRootNode]="true"
              [isRootNodeOne]="true"
              (datoDeSalida)="recibirDatosDeVuelta($event, 'vidrioInterno', 'Panio', btVidrioIPanio)">
            </app-menu-loco>
          </div>
        </div>
        <div class="w-2/12 px-2">
          <mat-form-field *ngIf="mostrarInteres" class="w-full input-vidrio-custom">
            <mat-label class="!font-normal">Interés Superficie</mat-label>
            <input (blur)="this.calcular()" matInput type="number" formControlName="interes"
              (blur)="this._calculadoraVidrioService.calcular()"
              class="bg-white !rounded-lg border border-zinc-200 inline-flex !py-0 !h-10">
          </mat-form-field>
        </div>

      </div>
    </div>
    <mat-dialog-content>
      <div class="flex -mx-2 items-center my-5">
        <div class="w-4/12 flex flex-col mb-1">
          <mat-label class=" !font-normal px-2 mb-1">Medidas
          </mat-label>
          <div class="w-full flex flex-row px-2">
            <mat-form-field class="w-6/12 mr-2 input-vidrio-custom">
              <input (blur)="this.calcular()" type="number" matInput formControlName='ancho' (click)="selectAll($event)"
                class='bg-white !rounded-lg border border-zinc-200 inline-flextype="text " !py-0 !h-10'>
              <mat-icon class="-rotate-90" matSuffix>straighten</mat-icon>
              <mat-hint>Ancho en mm</mat-hint>
            </mat-form-field>
            <mat-form-field class="w-6/12 ml-2 input-vidrio-custom">
              <input (blur)="this.calcular()" type="number" matInput formControlName='alto' (click)="selectAll($event)"
                class='bg-white !rounded-lg border border-zinc-200 inline-flextype="text " !py-0 !h-10'>
              <mat-icon class="-rotate-90" matSuffix>straighten</mat-icon>
              <mat-hint>Alto en mm</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="w-2/12 px-2 -mt-3">
          <mat-form-field class="w-full input-vidrio-custom">
            <mat-label class=" !font-normal">Cantidad</mat-label>
            <input (blur)="this.calcular()" type="number" matInput formControlName='cantidad'
              class='bg-white !rounded-lg border border-zinc-200 inline-flextype="text " !py-0 !h-10'>
          </mat-form-field>
        </div>
        <div class="w-2/12 -mt-3">
          <mat-form-field class="w-full input-vidrio-custom">
            <mat-label class=" !font-normal">Vidrio</mat-label>
            <input (blur)="this.calcular()" type="number" matInput formControlName='vidrio' [readonly]="true"
              class='bg-white !rounded-lg border border-zinc-200 inline-flextype="text " !py-0 !h-10'>
          </mat-form-field>
        </div>
      </div>
      <div class="flex -mx-2 items-center">
        <mat-form-field class="w-full">
          <mat-label class=" !font-normal">Detalle</mat-label>
          <textarea type="number" matInput formControlName='detalle'></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="flex w-full items-center justify-center">
        <div class="w-2/12 ml-auto">
          <button type="button" class="text-white bg-accent rounded-md p-2 w-full text-lg" (click)="onClose()">
            Cerrar
          </button>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
</div>