import { IUser } from '@core/models/auth/user';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { getDefaultNavigation } from './default';

export function getCompactNavigation(user: IUser): FuseNavigationItem[] {
  return [
   /*  {
      id: 'home',
      title: 'Inicio',
      type: 'basic',
      icon: 'heroicons_outline:home',
      link: '/common/maintenance',
    }, */
    {
      id: 'categories',
      title: 'Categorías',
      type: 'aside',
      icon: 'heroicons_outline:color-swatch',
      children: [...getDefaultNavigation(user)],
    },
  ]
}
