import { AppModule } from 'app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from 'environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      //console.log('Service Worker registrado con alcance:', registration.scope);
    }).catch((err) => {
      //console.error('Error al registrar el Service Worker:', err);
    });
}