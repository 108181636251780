import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedComponentModule } from "./components/shared-component.module";
import { MatIconModule } from "@angular/material/icon";
import { SanitizerPipe } from "./pipes/sanitizer";
import { CarouselItemDirective } from "./directives/carousel";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { CalculadoraVidriosComponent, MenuComponent } from "./components/calculadora-vidrios/calculadora-vidrios.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [
    MatTooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    MatTableModule    
  ],
  exports: [
    SharedComponentModule,
    MatIconModule,
    SanitizerPipe,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    MatIconModule,
    CarouselItemDirective,
    BreadcrumbsComponent,
    CalculadoraVidriosComponent
  ],
  declarations: [SanitizerPipe, CarouselItemDirective, BreadcrumbsComponent,CalculadoraVidriosComponent,MenuComponent],
})
export class SharedModule {}
