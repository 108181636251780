// breadcrumb.service.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface BreadcrumbItem {
  title: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbStack: BreadcrumbItem[] = [];

  // Inyecta el servicio de Router en el constructor
  constructor(private router: Router) {}

  getBreadcrumbs(): BreadcrumbItem[] {
    return this.breadcrumbStack;
  }

  pushBreadcrumb(item: BreadcrumbItem): void {
        // Verifica si ya existe un breadcrumb con la misma URL
    const duplicateIndex = this.breadcrumbStack.findIndex(b => b.url === item.url);

    if (duplicateIndex !== -1) {
      // Si ya existe, elimina todos los breadcrumbs posteriores a ese índice
      this.breadcrumbStack.splice(duplicateIndex + 1);
    } else {
      // Si no existe, agrega el nuevo breadcrumb
      this.breadcrumbStack.push(item);
    }
  }
/**
 * 
 * @param item si 'item' se manda, quita el último breadcrumbItem y se cambia por 'item'
 * sino, sólo se quita el último breadcrumbItem ingresado
 */
  popBreadcrumb(item:BreadcrumbItem=null): void {
    if(item==null){
      this.breadcrumbStack.pop();
    }else{
      this.breadcrumbStack.pop();
      this.pushBreadcrumb(item);
    }
  }

  clearBreadcrumbs(): void {
    this.breadcrumbStack = [];
  }

  navigateToBreadcrumb(index: number): void {
    if (index >= 0 && index < this.breadcrumbStack.length) {
      // Elimina los elementos posteriores al índice especificado
      this.breadcrumbStack.splice(index + 1);

      // Obtiene la URL de destino
      const destination = this.breadcrumbStack[index].url;

      // Utiliza el método navigateByUrl del Router para navegar a la URL
      this.router.navigateByUrl(destination);
    }
  }
}
