const $BASE_API = "https://aasacom.jeds.ar";
const $BASE_REST = "https://aasayii.jeds.ar/web";
//const $BASE_REST = "http://192.168.1.141/aasa_com_yii/web";
const $BASE_AASA_COM = "https://aasacom.jeds.ar";
const $BASE_AASA = "https://aberturas.com.ar";
const $APP_WEB = "https://cotizador.aberturas.com.ar";
const $CARGA_WEB = "https://aasacw.jeds.ar";
const $BUSQUEDAS = "https://busquedasbackend.aberturas.com.ar/web/rrhhs";
const $DOCUMENTOS = "https://documentosaasa.jeds.ar";


export const environment = {
      production: false,
      baseRest: $BASE_REST,
      appWeb: $APP_WEB,
      configFirebase: {
            apiKey: "AIzaSyCdxgANZPCbXz0KQV6rYVy1mfBuq6dSPfg",
            authDomain: "aasa-236601.firebaseapp.com",
            projectId: "aasa-236601",
            storageBucket: "aasa-236601.appspot.com",
            messagingSenderId: "855907669144",
            appId: "1:855907669144:web:67dde6147660046c282ced",
            measurementId: "G-7HDK8HNBHK"
      },
      login: {
            users: `${$BASE_REST}/view_usuario_logins`,
            //users: `${$CARGA_WEB}/usuarios_login.php`,
            usersApp: `${$BASE_API}/estadisticas/querybloques/usuarios_app.php`,
            login: `${$BASE_REST}/usuario/login`,
      },
      controlador: `${$BASE_AASA_COM}/controlador/`,
      busquedas: {
            postulantes: `${$BASE_AASA}/busquedas/rrhh_peticion.php`,
            puestos: `${$BASE_AASA}/busquedas/get_puesto.php`,
            ubicaciones: `${$BASE_AASA}/busquedas/get_ubicacion.php`,
            medios: `${$BASE_AASA}/busquedas/get_entero.php`,
            markDestacado: `${$BASE_AASA}/busquedas/destacar.php`,
            markVisto: `${$BASE_AASA}/busquedas/ver.php`,
            markLlamado: `${$BASE_AASA}/busquedas/llamar.php`,
            markObservado: `${$BASE_AASA}/busquedas/observar.php`,
      },
      calendario: {
            posts: `${$CARGA_WEB}/cw_post.php`,
            imagePost: `${$BASE_AASA_COM}/img/posts`,
            autorizarEvento: `${$CARGA_WEB}/cw_post_guardar.php`,
            imagenNoticia: `${$BASE_AASA_COM}/img/noticias`,
      },
      devoluciones: {
            get: `${$CARGA_WEB}/cw_remanente.php`,
            save: `${$CARGA_WEB}/cw_remanente_guardar.php`,
            delete: `${$CARGA_WEB}/cw_remanente_eliminar.php`,
            devolver: `${$CARGA_WEB}/cw_remanente_devolver.php`,
            camara: `${$CARGA_WEB}/cw_camara.php`,
            motivo: `${$CARGA_WEB}/cw_motivo.php`,
            operario: `${$CARGA_WEB}/cw_operario.php`,
            vidrio: `${$CARGA_WEB}/cw_vidrio.php`,
      },
      messages: `${$BASE_AASA}/get_message.php`,
      trazabilidad: {
            articulos: `${$CARGA_WEB}/cw_articulo_by_descripcion.php`,
            proveedores: `${$CARGA_WEB}/cw_proveedor_by_descripcion.php`,
            trazabilidad: `${$CARGA_WEB}/consultas/trazabilidad_consulta.php`,
            pdf: `${$CARGA_WEB}/consultas/mostrar_adjunto.php`,
      },
      whatsapp: `${$BASE_AASA}/sent_message.php`,
      mail: `${$BASE_AASA}/sent_mail.php`,

      api: {
            common: {
                  vendedores: `${$BASE_REST}/vendedors`,
                  localidades: `${$BASE_REST}/localidads`,
                  localidadesZonas: `${$BASE_REST}/view_localidad_zonas`,
                  notificaciones: `${$BASE_REST}/view_alertas`,
                  update_notificacion: `${$BASE_REST}/alerta_usuarios`,
                  usuarios: `${$BASE_REST}/usuarios`,
                  usuario_dispositivos: `${$BASE_REST}/usuario_dispositivos`,
            },
            gerencia: {
                  estadisticas: `${$BASE_REST}/estadisticas`,
            },
            clientes: {
                  prospecto_origenes: `${$BASE_REST}/prospecto_origens`,
                  presupuesto_estados: `${$BASE_REST}/presupuesto_estados`,
                  obras: `${$BASE_REST}/obras`,
                  contadores: `${$BASE_REST}/prospecto_contadors`,
                  prospectos: `${$BASE_REST}/prospectos`,
                  vista_prospectos: `${$BASE_REST}/view_prospectos`,
                  trazabilidad: `${$BASE_REST}/view_prospecto_trazabilidad`,
                  presupuestos: `${$BASE_REST}/presupuestos`,
                  presupuestos_vista_seccion: `${$BASE_REST}/view_presupuesto_seccions`,
                  presupuesto_vista_reportes: `${$BASE_REST}/view_presupuesto_reportes`,
                  usuarios: `${$BASE_REST}/usuarios`,
                  vendedores: `${$BASE_REST}/vendedors`,
                  clientes: `${$BASE_REST}/clientes`,
                  movimientos: `${$BASE_REST}/prospecto_movimientos`,
                  prospecto_llamados: `${$BASE_REST}/prospecto_llamados`,
                  view_prospecto_asignados: `${$BASE_REST}/view_prospecto_asignados`,
                  view_presupuesto_en_procesos: `${$BASE_REST}/view_presupuesto_en_procesos`,
                  view_presupuesto_en_revision: `${$BASE_REST}/view_presupuesto_en_revision`,
                  view_presupuesto_enviados: `${$BASE_REST}/view_presupuesto_enviados`,
                  view_prospecto_perdido_no_cotizados: `${$BASE_REST}/view_prospecto_perdido_no_cotizados`,
                  view_venta_pendientes: `${$BASE_REST}/view_venta_pendientes`,
                  view_venta_en_procesos: `${$BASE_REST}/view_venta_en_procesos`,
                  view_venta_terminadas: `${$BASE_REST}/view_venta_terminadas`,//ventas entregadas
                  reporte_venta_pdf: `${$BASE_REST}/venta/reporte_venta_pdf`,
                  com_configuracion: `${$BASE_REST}/com_configuracions`,
                  presupuesto_ponderacion_ponderar: `${$BASE_REST}/presupuesto_ponderacion/ponderar`,
                  reporte_presupuesto_pdf: `${$BASE_REST}/presupuesto/reporte_presupuesto_pdf`,
                  editar_referencia: `${$BASE_REST}/prospecto/editar_referencia`,

            },
            ventas: {
                  oportunidades: {
                        oportunidades: `${$BASE_REST}/oportunidads`,
                        usuario: `${$BASE_REST}/oportunidad_usuarios`,
                  },
                  mis_clientes: {
                        presupuestos: `${$BASE_REST}/presupuestos`,
                        solicitudes: `${$BASE_REST}/presupuesto_solicituds`,
                        usuarios: `${$BASE_REST}/usuarios`,
                        clientes: `${$BASE_REST}/clientes`,
                        solicitud_origenes: `${$BASE_REST}/presupuesto_solicitud_origens`,
                        solicitud_fuentes: `${$BASE_REST}/presupuesto_solicitud_fuentes`,
                        obras: `${$BASE_REST}/obras`,
                        presupuestos_importar_pvc: `${$BASE_REST}/presupuesto_importar_pvcs`,
                        presupuestos_importar_pvc_procesar_excel: `${$BASE_REST}/presupuesto_importar_pvc/procesar_excel`,
                        presupuestos_vista_seccion: `${$BASE_REST}/view_presupuesto_seccions`,
                        presupuesto_vista_reportes: `${$BASE_REST}/view_presupuesto_reportes`,
                        secciones: `${$BASE_REST}/seccions`,
                        documentos: `${$BASE_REST}/documentos`,
                        presupuestos_copia: `${$BASE_REST}/presupuesto_copias`,
                        configuracion: `${$BASE_REST}/configuracions`,
                        barrios: `${$BASE_REST}/barrios`,
                        bancos: `${$BASE_REST}/bancos`,
                        contratos: `${$BASE_REST}/contratos`,
                        forma_pago_contratos: `${$BASE_REST}/forma_pago_contratos`,
                        alternativas: `${$BASE_REST}/alternativas`,
                        ubicaciones: `${$BASE_REST}/ubicacions`,
                        abertura_otras: `${$BASE_REST}/aberturaotras`,
                        abertura_editable_chapa: {
                              ubicacions: `${$BASE_REST}/ubicacions`,
                              aberturaeditablechapa: `${$BASE_REST}/aberturachapas`,
                              categoria_vidrios: `${$BASE_REST}/categoriavidrios`,
                        },
                        abertura_editable: `${$BASE_REST}/aberturamanual`,
                        componente_editables: `${$BASE_REST}/componente_editables`,
                        componente_editables_total: `${$BASE_REST}/componente_editable/total_componentes`,
                        itemalternativas: `${$BASE_REST}/itemalternativas`,
                        itemalternativa_clonar: `${$BASE_REST}/itemalternativa/clonar`,
                        ventas: `${$BASE_REST}/view_ventas`,
                        terminar_venta: `${$BASE_REST}/venta/terminar`,
                        presupuesto_enviar_email: `${$BASE_REST}/presupuesto/enviar_email`,
                        enviar_whatsapp: `${$BASE_REST}/presupuesto/enviar_whatsapp`,
                  },
                  prospectos: {
                        prospectos: `${$BASE_REST}/view_prospectos`,
                        estados: `${$BASE_REST}/presupuesto_estados`,
                        movimientos: `${$BASE_REST}/prospecto_movimientos`,
                        contadores: `${$BASE_REST}/view_prospecto_contadors`,
                        trazabilidad: `${$BASE_REST}/view_prospecto_trazabilidad`,
                        prospecto_llamados: `${$BASE_REST}/prospecto_llamados`,
                        documentos_prospectos_planos: `${$DOCUMENTOS}/presupuestos/planos`,
                        presupuestos_vista_seccion: `${$BASE_REST}/view_presupuesto_seccions`,
                        presupuesto_vista_reportes: `${$BASE_REST}/view_presupuesto_reportes`,
                        presupuestos: `${$BASE_REST}/presupuestos`,
                        actualizar_plus_localidad: `${$BASE_REST}/presupuesto/actualizar_plus_localidad`,
                  },
                  seguimiento_profesionales: {
                        encuestas: `${$BASE_REST}/cliente_encuestas`,
                        profesiones: `${$BASE_REST}/profesions`,
                        usuarios: `${$BASE_REST}/usuarios`,
                        clientes: `${$BASE_REST}/view_clientes`,
                        obras: `${$BASE_REST}/obras`,
                        visitas: `${$BASE_REST}/visitas`,
                        com_configuracion: `${$BASE_REST}/com_configuracions`,
                  },
            },
            materiales: {
                  articulos: {
                        view_articulos: `${$BASE_REST}/view_articulos`,
                        articulos: `${$BASE_REST}/articulos`,
                        contadores: `${$BASE_REST}/view_articulo/contadores`,
                        rubros: `${$BASE_REST}/rubros`,
                        sectores: `${$BASE_REST}/sector_articulos`,
                        subsectores: `${$BASE_REST}/subsector_articulos`,
                        artUnidadMedida: `${$BASE_REST}/articulo_unidad_medidas`,
                        viewArtUnidadMedida: `${$BASE_REST}/view_articulo_unidad_medidas`,
                        cambiar_equivalencia: `${$BASE_REST}/view_articulo_movimiento_stock/cambiar_equivalencia`,
                        artProveedores: `${$BASE_REST}/articulo_proveedors`,
                        unidadMedida: `${$BASE_REST}/unidad_medidas`,
                        proveedores: `${$BASE_REST}/proveedors`,
                        imagenes: `${$BASE_REST}/imagens`,
                        movimientosGrafico: `${$BASE_REST}/view_articulo_movimiento_stocks`,
                        viewArticuloStockPanols: `${$BASE_REST}/view_articulo_stock_panols`,
                        viewArticuloMovimientoStocks: `${$BASE_REST}/view_articulo_movimiento_stocks`,
                        unificar: `${$BASE_REST}/articulo/unificar`,
                  },
                  pedidos: {
                        empleados: `${$BASE_REST}/empleados`,
                        view_empleados: `${$BASE_REST}/view_empleado_selects`,
                        importar_pedido_pvc: `${$BASE_REST}/compras_prepedido/importar_pedido_pvc`,
                        prepedidos: `${$BASE_REST}/compras_prepedidos`,
                        items_prepedido: `${$BASE_REST}/view_prepedido_detalles`,
                        pedidos: `${$BASE_REST}/compras_pedidos`,
                        ordenesCompra: `${$BASE_REST}/compras_orden_compras`,
                        proveedores: `${$BASE_REST}/proveedors`,
                        compras_generar_pedidos: `${$BASE_REST}/compras_generar_pedidos`,
                        compras_pedido_contestar_items: `${$BASE_REST}/compras_pedido_contestar_items`,
                        compras_generar_orden_compras: `${$BASE_REST}/compras_generar_orden_compras`,
                        compras_pedido_reportes: `${$BASE_REST}/compras_pedido_reportes`,
                        compras_oc_reportes: `${$BASE_REST}/compras_orden_compra_reportes`,
                        compras_generar_pedido_restantes: `${$BASE_REST}/compras_generar_pedido_restantes`,
                        compras_pedido_enviar_email: `${$BASE_REST}/compras_pedido/enviar_email`,
                        compras_orden_compra_enviar_email: `${$BASE_REST}/compras_orden_compra/enviar_email`,
                        compras_prepedido_notificar: `${$BASE_REST}/compras_prepedido/notificar`,
                        compras_pedido_proveedors: `${$BASE_REST}/compras_pedido_proveedors`,
                        compras_pedido_especial: `${$BASE_REST}/compras_pedido/especial`,
                        compras_pedido_agregar_proveedor: `${$BASE_REST}/compras_pedido/agregar_proveedor`,
                        compras_pedido_eliminar_proveedor: `${$BASE_REST}/compras_pedido/eliminar_proveedor`,
                        compras_pedido_cambio_articulo: `${$BASE_REST}/compras_pedido/cambio_articulo`,
                        compras_pedido_cambio_proveedor: `${$BASE_REST}/compras_pedido/cambio_proveedor`,
                  },
                  demorasOc: {
                        demoras: `${$BASE_REST}/view_orden_compra_demoras`,
                  },
                  inventarios: {
                        inventarios: `${$BASE_REST}/view_stock_inventarios`,
                        inventario_items: `${$BASE_REST}/view_stock_inventario_items`,
                  },
                  remitos_internos: {
                        remitos_internos: `${$BASE_REST}/view_remito_interno`,
                        remito_interno_item: `${$BASE_REST}/view_remito_interno_item`,
                  },
                  stock_panol: {
                        view_stock_panols: `${$BASE_REST}/view_stock_panols`,
                        stock_panols: `${$BASE_REST}/stock_panols`,
                  },
            },
            taller: {
                  planta_pintura: {
                        delete_remito_ppitem: `${$BASE_REST}/compras_remito_pp_item/eliminar_perfil`,
                        remitospp: `${$BASE_REST}/compras_remito_pps`,
                        remitospp_vista: `${$BASE_REST}/view_compras_remito_pps`,
                        proveedores: `${$BASE_REST}/proveedors`,
                        remito_pp_perfil_pendientes_vista: `${$BASE_REST}/view_compras_remito_pp_perfil_pendientes`,
                        remito_pp_items: `${$BASE_REST}/compras_remito_pp_items`,
                        remito_pp_items_vista: `${$BASE_REST}/view_compras_remito_pp_items`,
                        remito_pp_perfil_ocs_vista: `${$BASE_REST}/view_compras_remito_pp_perfil_ocs`,
                        tratamientos: `${$BASE_REST}/tratamientos`,
                        remitopp_tratamiento_perfiles_vista: `${$BASE_REST}/view_compras_remito_pp_tratamiento_perfils`,
                        tratamiento_ventas: `${$BASE_REST}/view_compras_remito_pp_tratamiento_ventas`,
                        remitopp_guardar_interno: `${$BASE_REST}/compras_remito_pp/guardar_interno`,
                        remitopp_guardar_items: `${$BASE_REST}/compras_remito_pp/guardar_items`,
                        documentos_remito_pp: `${$DOCUMENTOS}/remito_pp`
                  },
                  remitos_aasa: {
                        remitos_aasa: `${$BASE_REST}/remito_aasas`,
                        remito_aasa_items: `${$BASE_REST}/remito_aasa_items`,
                        proveedor_pendientes: `${$BASE_REST}/proveedor_pendientes`,
                        remitopp_pendientes: `${$BASE_REST}/remitopp_pendientes`,
                        tratamiento_pendientes: `${$BASE_REST}/tratamiento_pendientes`,
                        compras_remito_aasa_item_procesos: `${$BASE_REST}/compras_remito_aasa_item_procesos`,
                        remito_aasa_internos: `${$BASE_REST}/remito_aasa_internos`,
                        view_compras_remito_aasa_item_asignacions: `${$BASE_REST}/view_compras_remito_aasa_item_asignacions`,
                        compra_remitos_aasa: `${$BASE_REST}/compras_remito_aasas`,
                  },
                  orden_trabajo: {
                        orden_trabajo: `${$BASE_REST}/orden_trabajos`,
                        view_ot: `${$BASE_REST}/view_taller_ots`
                  }
            },
            reportes: {
                  view_empleado_planilla_sueldos: `${$BASE_REST}/view_empleado_planilla_sueldos`,
                  view_egreso_cantidad_kilo: `${$BASE_REST}/view_egreso_cantidad_kilos`,
                  view_egreso_retencion_pago: `${$BASE_REST}/view_egreso_retencion_pagos`,
                  view_venta_comprobante: `${$BASE_REST}/view_venta_comprobantes`,
            },
            rrhh: {
                  postulantes: $BUSQUEDAS,
            },
            configuraciones: {
                  precios: {
                        configuraciones: `${$BASE_REST}/configuracions`,
                        tamanoVidrios: `${$BASE_REST}/tamano_vidrios`,
                        precioPuertaChapa: `${$BASE_REST}/view_precios_puerta_chapas`,
                        tratamientoCortina: `${$BASE_REST}/view_cortina_tratamientos`,
                        puertaAluminio: `${$BASE_REST}/view_precios_puerta_aluminios`,
                        configuracion_precios: `${$BASE_REST}/configuracion_precios`,
                  },
                  zonas: {
                        zonas: `${$BASE_REST}/zonas`,
                  },
                  puertas_interior: {
                        puerta_placas: `${$BASE_REST}/puerta_placas`,//
                        marca_puerta_placas: `${$BASE_REST}/marca_puerta_placas`,
                        linea_puerta_placas: `${$BASE_REST}/linea_puerta_placas`,
                        tipo_puerta_placas: `${$BASE_REST}/tipo_puerta_placas`,
                        marcos: `${$BASE_REST}/marcos`,
                        emplacados: `${$BASE_REST}/emplacados`,
                        acabados: `${$BASE_REST}/acabados`,
                        medidas: `${$BASE_REST}/medidas`,
                        modelos: `${$BASE_REST}/modelos`,
                        aplicar_precio_masivo: `${$BASE_REST}/puerta_placa/aplicar_precio_masivo`,
                        calcular_precio_venta: `${$BASE_REST}/puerta_placa/calcular_precio_venta`,
                  }
            },
            calculadora: {
                  abertura_lista: {
                        lineas: `${$BASE_REST}/lineas`,
                        tratamientos: `${$BASE_REST}/tratamientos`,
                        tratamiento_aluminios: `${$BASE_REST}/tratamiento_aluminios`,
                        ubicacions: `${$BASE_REST}/ubicacions`,
                        linea_tipos: `${$BASE_REST}/view_abertura_lista_linea_tipos`,
                        tipo_mosquiteros: `${$BASE_REST}/aberturalista/tipo_mosquiteros`,
                        vidrio_cotizacion: `${$BASE_REST}/view_vidrio_cotizacion`,
                        categoria_vidrios: `${$BASE_REST}/categoriavidrios`,
                        camaras: `${$BASE_REST}/camaras`,
                        aberturalista_calcular: `${$BASE_REST}/aberturalista/calcular`,
                        aberturalistas: `${$BASE_REST}/aberturalistas`
                  },
                  abertura_editable: {
                        aberturamanual_limpiar: `${$BASE_REST}/aberturamanual/limpiar`,
                        get_itemalternativa_borrador: `${$BASE_REST}/aberturamanual/get_itemalternativa_borrador`,
                  },
                  puerta_lista: {
                        lineas: `${$BASE_REST}/lineas`,
                        tratamientos: `${$BASE_REST}/tratamientos`,
                        tratamiento_aluminios: `${$BASE_REST}/tratamiento_aluminios`,
                        ubicacions: `${$BASE_REST}/ubicacions`,
                        tipo_mosquiteros: `${$BASE_REST}/aberturalista/tipo_mosquiteros`,
                        vidrio_cotizacion: `${$BASE_REST}/view_vidrio_cotizacion`,
                        categoria_vidrios: `${$BASE_REST}/categoriavidrios`,
                        camaras: `${$BASE_REST}/camaras`,
                        aberturapuertalista_calcular: `${$BASE_REST}/aberturapuertalista/calcular`,
                        view_puerta_lista_linea_tipos: `${$BASE_REST}/view_puerta_lista_linea_tipos`,
                        aberturapuertalistas: `${$BASE_REST}/aberturapuertalistas`,
                        //view_puerta_lista_linea_tipos?filter[idlinea]=3&expand=imagen_data
                  },
                  puerta_chapa: {
                        modelos: `${$BASE_REST}/puertachapas`,
                        aberturapuertachapa_calcular: `${$BASE_REST}/aberturapuertachapa/calcular`,
                        aberturapuertachapas: `${$BASE_REST}/aberturapuertachapas`,
                  },
                  puerta_interior: {
                        ubicacions: `${$BASE_REST}/ubicacions`,
                        marco: `${$BASE_REST}/view_puerta_interior_marcos?`,
                        emplacado: `${$BASE_REST}/view_puerta_interior_emplacados?`,
                        acabado: `${$BASE_REST}/view_puerta_interior_acabados?`,
                        muro: `${$BASE_REST}/view_puerta_interior_muros?`,
                        medida: `${$BASE_REST}/view_puerta_interior_medidas?`,
                        marca: `${$BASE_REST}/marca_puerta_placas?expand=imagen_data&filter[activo]=1`,
                        modelo: `${$BASE_REST}/view_puerta_interior_modelos?expand=imagen_data&`,
                        tipo: `${$BASE_REST}/view_puerta_interior_tipos?expand=imagen_data&`,
                        aberturapuertaplaca_calcular: `${$BASE_REST}/aberturapuertaplaca/calcular`,
                        aberturapuertaplacas: `${$BASE_REST}/aberturapuertaplacas`
                  }
            },
            calculadora_vidrios: {
                  calcular: `${$BASE_REST}/itemalternativa/calculadora_vidrio`,
                  get_camaras:`${$BASE_REST}/camaras`,
                  get_categoria_vidrios: `${$BASE_REST}/categoriavidrios`,
            }
      },
      /* rol: {
            vendedor: [
                  { sector: '', id: 56 },
                  { sector: '', id: 65 },
                  { sector: '', id: 71 }
            ],
            admin: 54,
            analista: 69
      } */
};
