import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormateadorService } from '@shared/services/app/formateador.service';

@Directive({
    selector: '[appFormatInput]'
})
export class InputFormatDirective {
    @Input() appFormatInput: 'percentage' | 'currency' = 'percentage';

    constructor(
        private el: ElementRef,
        private control: NgControl,
        private formateador: FormateadorService
    ) {}

    @HostListener('input', ['$event.target.value'])
    onInput(value: string): void {
        if (this.appFormatInput === 'currency') {
            const formattedValue = this.formateador.formatInput(value);
            this.control.control?.setValue(formattedValue, { emitEvent: false });
        }
    }

    @HostListener('blur')
    onBlur(): void {
        if (this.appFormatInput === 'currency') {
            this.formateador.onCurrencyBlur(this.control.control!);
        } else if (this.appFormatInput === 'percentage') {
            this.formateador.onPercentageBlur(this.control.control);
        }
    }
}
