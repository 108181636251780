import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Navigation } from './navigation.types';
import { getCompactNavigation } from './configs/compact';
import { getDefaultNavigation } from './configs/default';
import { getFuturisticNavigation } from './configs/futuristic';
import { getHorizontalNavigation } from './configs/horizontal';
import { IUser } from '@core/models/auth/user';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(2);
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  getNavigation(): Observable<Navigation> {
    const user: IUser = JSON.parse(localStorage.getItem('user'))?.user;
    const navigationData = {
      default: getDefaultNavigation(user),
      compact: getCompactNavigation(user),
      futuristic: getFuturisticNavigation(user),
      horizontal: getHorizontalNavigation(user),
    };
    return of(navigationData).pipe(tap(navigation => this._navigation.next(navigation)));
  }
}

// import { Observable, ReplaySubject, of, tap, switchMap, take, map } from "rxjs";
// import { Injectable } from "@angular/core";
// import { Navigation } from "./navigation.types";
// import { getCompactNavigation } from "./configs/compact";
// import { getDefaultNavigation } from "./configs/default";
// import { getFuturisticNavigation } from "./configs/futuristic";
// import { getHorizontalNavigation } from "./configs/horizontal";
// import { IUser } from "@core/models/auth/user";
// import { ProspectosRootService } from "@shared/services/app/prospectos-root.service";
// import { FuseNavigationItem } from "@fuse/components/navigation/navigation.types";
// import { AuthLocalService } from "@core/auth/auth-local.service";
// import { IProspectoRoot } from "@shared/models/prospectos-root";

// const BADGE_CLASS =
//   "fuse-vertical-navigation-item-badge-content px-2 bg-pink-600 text-white rounded-full";
// let counter: number = 0;
// let navigationData=null;
// @Injectable({
//   providedIn: "root",
// })
// export class NavigationService {
//   private _navigation: ReplaySubject<Navigation> =
//     new ReplaySubject<Navigation>(2);
//   /**
//    * Constructor
//    */
//   constructor(
//     private _prospectosRootService: ProspectosRootService,
//     private authLocalService: AuthLocalService
//   ) { }

//   // -----------------------------------------------------------------------------------------------------
//   // @ Public methods
//   // -----------------------------------------------------------------------------------------------------

//   /**
//    * Get all navigation data
//    */
//   getNavigation(): Observable<Navigation> {
//     //console.log('navvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv', counter);
//     //counter++;
//     const user: IUser = this.authLocalService.getCurrentUser();
//     this._prospectosRootService.getAll().pipe(take(1)).subscribe(result=>{console.log("GetAll(en navig service)", result)});
//      navigationData = {
//       default: getDefaultNavigation(user),
//       compact: getCompactNavigation(user),
//       futuristic: getFuturisticNavigation(user),
//       horizontal: getHorizontalNavigation(user),
//     };
    
//     return this._prospectosRootService.getAll().pipe(
//       take(1),
//       switchMap((prospectos) =>
//         of(navigationData).pipe(
//           map((navigation: Navigation) => {
//             navigation.default = getDefaultNavigation(user)?.map(
//               (item: FuseNavigationItem) => {
//                 return {
//                   ...item,
//                   children: item.children
//                     ? item.children.map((child: FuseNavigationItem) => ({
//                       ...child,
//                       badge: this.getProspectosBadge(item, child, prospectos),
//                     }))
//                     : [],
//                 };
//               }
//             );
//             (navigation.compact = [
//               {
//                 id: "home",
//                 title: "Inicio",
//                 type: "basic",
//                 icon: "heroicons_outline:home",
//                 link: "/common/maintenance",
//               },
//               {
//                 id: "categories",
//                 title: "Categorías",
//                 type: "aside",
//                 icon: "heroicons_outline:color-swatch",
//                 children: [...navigation.default],
//               },
//             ]),
//               (navigation.futuristic = [...navigation.default]);
//             navigation.horizontal = [
//               {
//                 id: "home",
//                 title: "Inicio",
//                 type: "basic",
//                 icon: "heroicons_outline:home",
//                 link: "/common/maintenance",
//               },
//               {
//                 id: "categories",
//                 title: "Categorías",
//                 type: "aside",
//                 icon: "heroicons_outline:color-swatch",
//                 children: [...navigation.default],
//               },
//             ];
//             return navigation;
//           })
//         )
//       )
//     );
//   }

//   getProspectosBadge(
//     parent: FuseNavigationItem,
//     child: FuseNavigationItem,
//     prospectos: IProspectoRoot
//   ): any {
//     console.log("seteando los badges BADGES");
//     return parent.id === "ventas.prospectos" &&
//       prospectos[
//       child.id.split(".").length > 1
//         ? child.id.split(".")[1].replace("-", "_")
//         : child.id
//       ]
//       ? {
//         title:
//           prospectos[
//           child.id.split(".").length > 1
//             ? child.id.split(".")[1].replace("-", "_")
//             : child.id
//           ],
//         classes: BADGE_CLASS,
//       }
//       : null;
//   }
// }
