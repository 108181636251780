import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  USERS_PENDING = '[AUTH: USERS PENDING]',
  USERS_SUCCESS = '[AUTH: USERS SUCCESS]',
  USERS_ERROR = '[AUTH: USERS ERROR]',
  SET_USER = '[AUTH: SET USER]',
  CLEAR_DATA = '[AUTH: CLEAR DATA]',
}

export class UsersFetchPending implements Action {
  readonly type = AuthActionTypes.USERS_PENDING;
  constructor(public payload: any) {}
}
export class UsersFetchSuccess implements Action {
  readonly type = AuthActionTypes.USERS_SUCCESS;
  constructor(public payload: any) {}
}
export class UsersFetchError implements Action {
  readonly type = AuthActionTypes.USERS_ERROR;
  constructor(public payload: any) {}
}
export class SetUser implements Action {
  readonly type = AuthActionTypes.SET_USER;
  constructor(public payload: any) {}
}
export class ClearData implements Action {
  readonly type: AuthActionTypes.CLEAR_DATA;
}

export type AuthActions = UsersFetchPending | UsersFetchSuccess | UsersFetchError | SetUser | ClearData;
