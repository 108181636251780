<!-- Notifications toggle -->
<button
  mat-icon-button
  (click)="openPanel()"
  #notificationsOrigin
  [class]="getBlink()"
>
  <ng-container *ngIf="unreadCount > 0">
    <span
      class="absolute top-0 right-0 left-0 flex items-center justify-center h-3"
    >
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 text-indigo-50 text-xs font-medium"
      >
        {{ unreadCount }}
      </span>
    </span>
  </ng-container>
  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-120 sm:w-120 sm:rounded-2xl overflow-hidden shadow-lg h-[100vh] lg:h-[90vh]"
  >
    <!-- Header -->
    <div
      class="flex shrink-0 items-center py-2 pr-4 pl-6 bg-slate-100 text-slate-500 border-b border-slate-200"
    >
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:x'"
          ></mat-icon>
        </button>
      </div>
      <mat-icon
        class="icon-size-7 text-yellow_aberturas-500 mr-4"
        [svgIcon]="'heroicons_solid:mail-open'"
      ></mat-icon>
      <div class="text-lg font-medium leading-10">Notificaciones</div>
      <mat-slide-toggle class="ml-auto">
        <span class="text-xs">Mostrar solo las no leídas</span>
      </mat-slide-toggle>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto overflow-y-auto bg-card pb-4">
      <div class="ml-auto p-3">
        <a
          class="hover:text-slate-400 text-slate-500 cursor-pointer text-md"
          matTooltip="Marcar todas como leídas"
          [disabled]="unreadCount === 0"
          (click)="markAllAsRead()"
        >
          <i>Marcar todo como leído</i>
        </a>
      </div>
      <!-- Notifications -->
      <ng-container
        *ngFor="let notification of notifications; trackBy: trackByFn"
      >
        <div
          class="flex group my-1 mx-5 rounded-lg shadow-md hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
          [ngClass]="{ unread: !notification.read }"
        >
          <!-- Notification with a link -->
          <ng-container *ngIf="notification.link">
            <!-- Normal links -->
            <ng-container *ngIf="!notification.useRouter">
              <a
                class="flex flex-auto py-5 pl-6 cursor-pointer"
                [href]="notification.link"
              >
                <ng-container
                  *ngTemplateOutlet="notificationContent"
                ></ng-container>
              </a>
            </ng-container>
            <!-- Router links -->
            <ng-container *ngIf="notification.useRouter">
              <a
                class="flex flex-auto py-5 pl-6 cursor-pointer"
                [routerLink]="notification.link"
              >
                <ng-container
                  *ngTemplateOutlet="notificationContent"
                ></ng-container>
              </a>
            </ng-container>
          </ng-container>

          <!-- Notification without a link -->
          <ng-container *ngIf="!notification.link">
            <div class="flex flex-auto py-5 pl-6">
              <ng-container
                *ngTemplateOutlet="notificationContent"
              ></ng-container>
            </div>
          </ng-container>

          <!-- Actions -->
          <div class="relative flex flex-col my-5 mr-6 ml-2">
            <!-- Indicator -->
            <button
              class="w-6 h-6 min-h-6"
              mat-icon-button
              (click)="toggleRead(notification)"
              matTooltip="Marcar como leída"
            >
              <span
                class="w-2 h-2 rounded-full"
                [ngClass]="{
                  'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100':
                    notification.read,
                  'bg-red-500': !notification.read
                }"
              ></span>
            </button>
            <!-- Remove -->
          </div>
        </div>

        <!-- Notification content template -->
        <ng-template #notificationContent>
          <!-- Icon -->
          <ng-container *ngIf="notification.icon && !notification.image">
            <div
              class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700"
            >
              <mat-icon class="icon-size-5" [svgIcon]="notification.icon">
              </mat-icon>
            </div>
          </ng-container>
          <!-- Image -->
          <ng-container *ngIf="notification.image">
            <img
              class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
              [src]="notification.image"
              [alt]="'Notification image'"
            />
          </ng-container>
          <!-- Title, description & time -->
          <div class="flex flex-col flex-auto">
            <ng-container *ngIf="notification.tipo">
              <div class="flex items-center gap-1">
                <div
                  class="flex items-center justify-center w-14 h-14 rounded-lg bg-red-400"
                >
                  <mat-icon
                    [svgIcon]="getNotificationIcon(notification)"
                    class="icon-size-8 text-black"
                  ></mat-icon>
                </div>
                <div class="w-10/12 pl-2">
                  <div class="flex justify-between items-center mb-2">
                    <span class="font-semibold">{{
                      getNotificationTitle(notification)
                    }}</span>
                    <div class="text-sm leading-none text-secondary">
                      {{ notification.fecha | date : "dd MMM, h:mm a" }}
                    </div>
                  </div>
                  <ng-container *ngIf="notification.descripcion">
                    <div [innerHTML]="notification.descripcion"></div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>

      <!-- No notifications -->
      <ng-container *ngIf="!notifications || !notifications.length">
        <div
          class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
        >
          <div
            class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100"
          >
            <mat-icon
              class="text-primary-500-700"
              [svgIcon]="'heroicons_outline:bell'"
            ></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">
            No tienes notificaciones
          </div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
            Cuando tengas notificacioness se mostraran aquí.
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
