<!-- navbar.component.html -->

<!-- <nav>gdhdshgddhdfghfddfgh
    <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let i = index">
      <a (click)="navigateTo(i)">{{ breadcrumb }}</a>
      <span *ngIf="i < getBreadcrumbs().length - 1"> / </span>
    </ng-container>
  </nav> -->
<!-- Header -->
<div class="flex flex-col sm:flex-row flex-0  p-0  dark:bg-transparent">
    <div class="flex flex-row flex-1 min-w-0">
        <div *ngIf="getBreadcrumbs().length>1" class="whitespace-nowrap">

            <a (click)="navigateTo(getBreadcrumbs().length-2)"
                class="ml-1 text-primary-500 cursor-pointer text-lg font-bold flex items-center">
                <mat-icon class="icon-size-10 text-primary-500" [svgIcon]="'heroicons_solid:chevron-left'"></mat-icon>
                {{getBreadcrumbs()[getBreadcrumbs().length-2].title }}</a>


        </div>
        <!-- <div  *ngFor="let breadcrumb of getBreadcrumbs(); let i = index" class="flex flex-row flex-nowrap  font-medium">
            
            <div class="flex items-center ml-1 whitespace-nowrap">
                
                <a (click)="navigateTo(i)" class="ml-1 text-primary-500 cursor-pointer">{{ breadcrumb.title }}</a>
                <mat-icon  *ngIf="i < getBreadcrumbs().length - 1"
                    class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
            </div>
            
        </div> --> <!--  <span *ngIf="i < getBreadcrumbs().length - 1"> / </span> -->

    </div>
</div>