import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CalculadoraVidrioService {
    constructor(
        private http: HttpClient,
    ) { }
    calcular(payload: any): Observable<any> {
        return this.http.post(environment.api.calculadora_vidrios.calcular, payload)
    }
    getCalculo(data: any): Observable<any> {
        return this.http.post<any>(environment.api.calculadora_vidrios.calcular, data);
    }
    getCamaras(filtro = ''): Observable<any> {
        return this.http.get<any>(environment.api.calculadora_vidrios.get_camaras + filtro);
    }
    getCategoriaVidrios(filtro = ''): Observable<any> {
        return this.http.get<any>(environment.api.calculadora_vidrios.get_categoria_vidrios + filtro);
    }
}
