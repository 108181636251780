import * as actions from '@root-store/auth-store/auth.actions';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Subject, combineLatest, filter, map, takeUntil, timer, finalize, takeWhile, tap } from 'rxjs';
import { AppConfig } from '@core/config/app.config';
import { AuthLocalService } from '@core/auth/auth-local.service';
import { DOCUMENT } from '@angular/common';
import { FUSE_VERSION } from '@fuse/version';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FusePlatformService } from '@fuse/services/platform';
import { IUser } from '@core/models/auth/user';
import { Layout } from './layout.types';
import { Store } from '@ngrx/store';
import { AppAlertService } from '@shared/services/app/alert.service';
import { IAlertConfig } from '@shared/models/alert';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LayoutComponent implements OnInit, OnDestroy {
  config: AppConfig;
  layout: Layout;
  scheme: 'dark' | 'light';
  theme: string;
  user: IUser;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  _alertConfig: IAlertConfig;
  showAlert = false;
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: any,
    private _renderer2: Renderer2,
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fusePlatformService: FusePlatformService,
    private authLocalService: AuthLocalService,
    private store: Store,
    private alert: AppAlertService
  ) {
    this.user = this.authLocalService.getCurrentUser();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the theme and scheme based on the configuration
    combineLatest([
      this._fuseConfigService.config$,
      this._fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)']),
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(([config, mql]) => {
          this.user = this.authLocalService.getCurrentUser();
          const options = {
            scheme: this.user?.scheme ? this.user.scheme : config.scheme,
            theme: this.user?.theme ? this.user.theme : config.theme,
          };

          // If the scheme is set to 'auto'...
          if (config.scheme === 'auto') {
            // Decide the scheme using the media query
            options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
          }

          return options;
        })
      )
      .subscribe(options => {
        // Store the options
        this.scheme = options.scheme;
        this.theme = options.theme;

        // Update the scheme and theme
        this._updateScheme();
        this._updateTheme();
      });

    // Subscribe to config changes
    this._fuseConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: AppConfig) => {
      // Store the config
      this.config = config;
      this.user = this.authLocalService.getCurrentUser();
      // Update the layout
      this._updateLayout();
    });

    // Subscribe to NavigationEnd event
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        // Update the layout
        this._updateLayout();
      });

    // Set the app version
    this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'fuse-version', FUSE_VERSION);

    // Set the OS name
    this._renderer2.addClass(this._document.body, this._fusePlatformService.osName);

    // Set User if reload
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/sign-out') {
          this.store.dispatch(new actions.SetUser(null));
        }
      }
    });
    this.store.dispatch(new actions.SetUser(this.authLocalService.getCurrentUser()));

    this.subscribeAlert();
  }

  subscribeAlert() {
    this.alert.emitConfig.subscribe({
      next: (config: IAlertConfig) => {
        this.showAlert = true;
        this._alertConfig = {
          ...config,
          appearance: 'border',
        };
        let time = 2;
        timer(1000, 1000)
          .pipe(
            finalize(() => {
              this.showAlert = false;
              this._alertConfig = null;
            }),
            takeWhile(() => time > 0),
            takeUntil(this._unsubscribeAll),
            tap(() => (time -= 1))
          )
          .subscribe();
      },
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the selected layout
   */
  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config
    this.layout = this.user?.layout ? (this.user.layout as Layout) : this.config.layout;
    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config
    const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout') as Layout;
    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;
      if (this.config) {
        this.config.layout = layoutFromQueryParam;
      }
    }

    // 3. Iterate through the paths and change the layout as we find
    // a config for it.
    //
    // The reason we do this is that there might be empty grouping
    // paths or componentless routes along the path. Because of that,
    // we cannot just assume that the layout configuration will be
    // in the last path's config or in the first path's config.
    //
    // So, we get all the paths that matched starting from root all
    // the way to the current activated route, walk through them one
    // by one and change the layout as we find the layout config. This
    // way, layout configuration can live anywhere within the path and
    // we won't miss it.
    //
    // Also, this will allow overriding the layout in any time so we
    // can have different layouts for different routes.
    const paths = route.pathFromRoot;
    paths.forEach(path => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
        // Set the layout
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  /**
   * Update the selected scheme
   *
   * @private
   */
  private _updateScheme(): void {
    // Remove class names for all schemes
    this._document.body.classList.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList.add(this.scheme);
  }

  /**
   * Update the selected theme
   *
   * @private
   */
  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(this.theme);
  }
}
