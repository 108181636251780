import { AuthActionTypes, AuthActions } from './auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '@core/models/auth/user';

export interface IUsersStore {
  users: IUser[];
  selected: IUser;
  error: HttpErrorResponse | boolean;
}

export const initialState: IUsersStore = {
  users: [],
  selected: null,
  error: null,
};

// eslint-disable-next-line func-style
export function reducer(state = initialState, action: AuthActions): IUsersStore {
  switch (action.type) {
    case AuthActionTypes.USERS_PENDING:
      return {
        ...state,
      };

    case AuthActionTypes.USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.map(user => {
          //user.permisos = user.permisos.map(permiso => Number(permiso));
          user.permisos = JSON.parse(user.permisos);
          user.roles = JSON.parse(user.roles);
          /* console.log(user) */
          return user;
        }),
        error: false,
      };

    case AuthActionTypes.USERS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case AuthActionTypes.SET_USER:
      return {
        ...state,
        selected: action.payload,
      };

    case AuthActionTypes.CLEAR_DATA:
      return {
        ...state,
        users: [],
      };

    default:
      return state;
  }
}
